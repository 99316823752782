<template>
  <div>
    <q-card>
      <q-card-section>
        <q-form class="q-gutter-md">
          <div style="text-align: center">
            <svg
              width="60px"
              viewBox="0 0 1282 1282"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xml:space="preserve"
              xmlns:serif="http://www.serif.com/"
              style="
                fill-rule: evenodd;
                clip-rule: evenodd;
                stroke-linejoin: round;
                stroke-miterlimit: 2;
              "
            >
              <g transform="matrix(4.16667,0,0,4.16667,653.1,360.308)">
                <path
                  d="M0,134.698C47.001,134.698 71.299,104.824 74.087,71.763C77.008,37.129 74.486,
                  8.829 0,8.829C-74.486,8.829 -84.443,37.906 -79.664,65.789C-73.677,100.712 -47.001,
                  134.698 0,134.698M150.902,178.364L-113.937,221.172L-137.575,74.923C-133.137,
                  62.213 -130.981,51.884 -128.419,48.661C-128.419,48.661 -125.537,44.733 -119.987,
                  43.247C-119.926,43.233 -119.866,43.219 -119.805,43.205C-119.605,43.153 -119.396,
                  43.113 -119.188,43.068C-118.058,42.848 -116.792,42.713 -115.394,42.695C-113.995,
                  42.713 -112.729,42.848 -111.599,43.068C-111.392,43.113 -111.182,43.153 -110.982,
                  43.205C-110.921,43.219 -110.861,43.233 -110.801,43.247C-105.25,44.733 -102.368,
                  48.661 -102.368,48.661C-94.02,59.163 -89.995,145.098 2.788,143.062C104.733,
                  140.826 79.266,51.051 95.198,37.11C95.198,37.11 98.385,32.728 102.766,32.728L105.156,
                  7.236C105.156,7.236 37.917,-2.34 5.178,-1.528C-58.951,0.066 -81.655,15.999 -109.538,
                  15.999L-121.25,15.999C-130.098,15.999 -138.426,14.393 -147.721,12.152L-156.744,
                  -43.667L108.095,-86.474L150.902,178.364Z"
                  style="fill: rgb(0, 0, 0); fill-rule: nonzero"
                />
              </g>
            </svg>
          </div>
          <q-input
            square
            filled
            clearable
            v-model="email"
            type="email"
            label="email"
            :rules="[(email) => !!email || 'Email obrigatório']"
          />
          <q-input
            square
            filled
            clearable
            v-model="password"
            type="password"
            label="senha"
            :rules="[(password) => !!password || 'Senha obrigatório']"
          />
        </q-form>
      </q-card-section>
      <q-card-actions class="q-px-md">
        <q-btn
          unelevated
          :loading="loading"
          label-color="indigo-14"
          color="indigo-14"
          dark
          size="lg"
          class="full-width"
          label="ENTRAR"
          @click="doLogin"
        />
      </q-card-actions>
      <q-card-section class="text-center q-pa-none">
        <p class="text-grey-6" @click="recover">Esqueceu sua senha?</p>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
export default {
  name: 'LoginForm',

  data() {
    return {
      email: null,
      password: null,
      loading: false
    }
  },

  methods: {
    doLogin() {
      this.loading = true
      this.$axios
        .post('/auth/login', {
          email: this.email,
          password: this.password
        })
        .then((res) => {
          this.$store.dispatch('auth/login', res.data)
          this.$router.push('/dashboard')
        })
        .finally(() => (this.loading = false))
    },

    recover() {
      this.$q
        .dialog({
          title: 'Recuperar senha',
          message: 'Digite seu email para recuperar sua senha',
          cancel: true,
          persistent: true,
          prompt: {
            model: '',
            type: 'Digite seu email' // optional
          }
        })
        .onOk((email) => {
          if (email) {
            this.$axios
              .post('/password/email', {
                email: email
              })
              .then(({ data }) => {
                this.$q.notify({
                  progress: true,
                  timeout: 5000,
                  position: 'top',
                  message: data.message,
                  color: 'positive',
                  multiLine: true,
                  avatar: '/favicon.png'
                })
              })
          }
        })
    }
  },
  mounted() {
    // window.location.reload(true);
  }
}
</script>

<style lang="sass" scoped>
.q-card
  background: #ffffff!important
</style>
