<template>
  <div
    :class="boxClass"
    :style="`background-color:${color};color:${textColor};`"
    @click="openRoute"
  >
    <div v-if="loading" class="loadingBox">
      <b-spinner label="Large Spinner"></b-spinner>
    </div>
    <div v-else>
      <div v-if="total > 0" class="inside">
        <h1 class="largeTitle">{{ total }}</h1>
        <a
          href="#"
          class="font-weight-bold font-size-h6"
          :style="`color:${textColor}!important`"
        >
          {{ title }}
        </a>
      </div>
      <div v-else class="inside">
        <div
          :style="`color:${textColor}!important;height: 100px;display: flex;justify-content: center;align-items: center;`"
        >
          {{ message }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NumberWidget',

  props: ['title', 'color', 'textColor', 'route', 'total', 'loading'],

  computed: {
    message() {
      const { route, total, title } = this

      if (route === '/ordens/nova/certificado' && total === 0) {
        return 'Clique aqui e compre créditos para emitir certificados'
      }

      if (route === '/certificates' && total === 0) {
        return 'Nenhum certificado emitido no período'
      }

      if (title === 'Certificados a emitir' && total === 0) {
        return 'Não existem certificados para emitir neste período'
      }

      if (
        title === 'Certificados Emitidos' &&
        total === 0 &&
        route === '/certificates'
      ) {
        return 'Nenhum certificado emitido no período'
      }

      return total
    },

    boxClass() {
      return {
        col: true,
        'px-6': true,
        'py-2': true,
        'rounded-xl': true,
        'mb-7': true,
        'min-h': true,
        'text-center': true
      }
    }
  },

  methods: {
    openRoute() {
      if (this.route === '/ordens/nova/certificado' && this.total == 0) {
        this.$alert(
          'Você não possui créditos para emitir certificados personalizados'
        )
      } else if (this.route === '/certificates' && this.total == 0) {
        this.$alert('Não existem certificados emitidos para este período')
      } else if (this.route === '/certificates' && this.total == 0) {
        this.$alert('Não existem certificados emitidos para este período')
      } else {
        this.$router.push(this.route)
      }
    }
  }
}
</script>
<style lang="css">
.largeTitle {
  font-size: 48px;
  font-weight: bold;
  margin: 0;
}
.loadingBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 128px;
  height: 98px;
}
.min-h {
  min-height: 120px;
}
.inside {
  min-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
