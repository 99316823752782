import Router from 'vue-router'
import Vue from 'vue'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      component: () => import('@/view/layout/Layout'),
      children: [
        {
          path: '/dashboard',
          name: 'dashboard',
          component: () => import('@/view/pages/Dashboard.vue')
        }
      ]
    },
    {
      path: '/scanner',
      component: () => import('@/view/layout/OptLayout'),
      children: [
        {
          path: '',
          component: () => import('@/view/pages/Scanner.vue')
        }
      ]
    },
    {
      path: '/',
      redirect: '/ordens',
      component: () => import('@/view/layout/OptLayout'),
      children: [
        {
          path: '/ordens/nova/produto',
          name: 'certificate.integration',
          component: () => import('@/view/pages/orders/NewOrderProduct.vue')
        },
        {
          path: '/categories',
          name: 'category.list',
          component: () => import('@/view/pages/category/CategoryPage.vue')
        },
        {
          path: '/categories/create',
          name: 'category.create',
          component: () => import('@/view/pages/category/CategoryPage.vue')
        },
        {
          path: '/categories/:id',
          name: 'category.edit',
          component: () => import('@/view/pages/category/CategoryPage.vue')
        },
        {
          path: '/stores',
          name: 'store.list',
          component: () => import('@/view/pages/store/StorePage.vue')
        },
        {
          path: '/laboratories',
          name: 'laboratory.list',
          component: () => import('@/view/pages/laboratory/LaboratoryPage.vue')
        },
        {
          path: '/certificates',
          name: 'certificate.list',
          component: () =>
            import('@/view/pages/certificate/CertificatePage.vue')
        },
        {
          path: '/me',
          name: 'profile',
          component: () => import('@/view/pages/profile/Profile-1.vue')
        },
        {
          path: '/faq',
          name: 'faq',
          component: () => import('@/view/pages/faq/FaqPage.vue')
        },
        {
          path: '/ordens/nova/certificado',
          name: 'certificate.manual',
          component: () => import('@/view/pages/orders/NewOrderManual.vue')
        },
        {
          path: '/ordens',
          name: 'orders',
          component: () => import('@/view/pages/orders/OrdersPageList.vue')
        },
        {
          path: '/salesmen',
          name: 'salesmen',
          component: () => import('@/view/pages/salesman/SalesmanPageList.vue')
        },
        {
          path: '/ordens/:id',
          name: 'order.id',
          component: () => import('@/view/pages/orders/OrdersPageForm.vue')
        }
      ]
    },
    {
      path: '/',
      component: () => import('@/view/pages/auth/Auth'),
      children: [
        {
          name: 'login',
          path: '/login',
          component: () => import('@/view/pages/auth/Login')
        },
        {
          name: 'register',
          path: '/register',
          component: () => import('@/view/pages/auth/Register')
        },
        {
          name: 'convite',
          path: '/convite',
          component: () => import('@/view/pages/auth/RegisterSalesman')
        },
        {
          name: 'forgot',
          path: '/forgot',
          component: () => import('@/view/pages/auth/Forgot')
        }
      ]
    },
    {
      path: '*',
      redirect: '/404'
    },
    {
      path: '/404',
      name: '404',
      component: () => import('@/view/pages/error/Error-1.vue')
    }
  ]
})
