<template>
  <b-form-select
    v-model="year"
    :options="years"
    @change="emitChanged"
  ></b-form-select>
</template>

<script>
export default {
  name: 'YearSwitcher',

  data: () => ({
    year: null
  }),

  computed: {
    years() {
      let date = new Date()
      let years = []
      for (let i = date.getFullYear() - 3; i <= date.getFullYear(); i++) {
        years.push(i)
      }

      return years
    }
  },

  methods: {
    emitChanged(year) {
      this.$emit('input', year)
    }
  },

  mounted() {
    let date = new Date()
    this.year = date.getFullYear()
    this.emitChanged(this.year)
  }
}
</script>

<style lang="scss">
h3 {
  font-size: 1.7em !important;
  font-weight: bold !important;
}
.custom-select {
  background: #ffffff33;
  border: none !important;
  color: #000 !important;
  font-weight: bold !important;
}
</style>
