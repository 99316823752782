<template>
  <q-card class="bg-grey-9 my-card" @click="$router.push('/ordens')">
    <q-card-section>
      <div class="text-subtitle2 text-center" style="color: #494d61">
        PEDIDOS
      </div>
      <div class="text-h3 text-center" style="color: #494d61">{{ total }}</div>
    </q-card-section>
  </q-card>
</template>

<script>
export default {
  name: 'TotalOrders',

  data() {
    return {}
  },

  computed: {
    orders() {
      return this.$store.state.orders.orders
    },

    total() {
      if (!this.orders) {
        return 0
      }
      return this.orders.length
    }
  },

  methods: {
    getOrders() {
      if (!this.orders) {
        this.$store.dispatch('orders/fetchOrders')
      }
    }
  },

  mounted() {
    this.getOrders()
  }
}
</script>
