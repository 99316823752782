<template>
  <div>
    <button
      class="botao"
      v-if="$route.path === '/certificates'"
      @click="openModal"
    >
      <i class="menu-icon flaticon2-search"></i>
    </button>
    <b-modal
      id="modal-search-certificate"
      title="Buscar"
      @ok="search"
      @cancel="closeModal"
      cancel-disabled
      cancel-title="cancelar"
      ok-title="BUSCAR"
      ok-only
      modal-cancel
    >
      <div>
        <p class="my-4">
          <b-input
            placeholder="Digite o que procura..."
            color="indigo-14"
            v-model="params.search"
          />
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'SearchCertificate',

  data: () => ({
    loading: false,
    params: {
      search: null
    }
  }),

  methods: {
    ...mapActions('certificates', ['fetchCertificates']),

    openModal() {
      this.$bvModal.show('modal-search-certificate')
    },

    search() {
      this.fetchCertificates(this.params)
      this.loading = true
    },

    closeModal() {
      this.$bvModal.hide('modal-search-certificate')
    }
  }
}
</script>

<style lang="scss" scoped>
.botao {
  border: 1px solid #1a1a27;
  background: #1a1a27;
  margin-right: 20px;
}
</style>
