<template>
  <div v-if="!prism">
    <q-card>
      <q-card-section
        class="self-center q-mt-md self-center"
        @click.prevent.native="choosePrism('c')"
      >
        <div class="text-h5">Com prisma</div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ProductPrescriptionPrismChooser',

  computed: {
    ...mapGetters('orders', {
      prism: 'GET_PRISM'
    })
  },

  methods: {
    ...mapActions('menus', ['addPanel']),
    ...mapActions('orders', ['setPrism']),

    choosePrism(val) {
      let panel = {
        value: 'prism',
        label: val === 'c' ? 'Com Prisma' : 'Sem prisma',
        has_products: false,
        action: 'removePrism'
      }

      this.addPanel(panel)

      if (val === 'c') this.setPrism(true)
      if (val === 's') this.setPrism(false)
    }
  }
}
</script>

<style lang="scss" scoped></style>
