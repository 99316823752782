<template>
  <div>
    <div v-if="loading" class="text-center">
      <b-spinner
        style="width: 4rem; height: 4rem"
        label="Large Spinner"
      ></b-spinner>
    </div>
    <b-table
      striped
      hover
      :items="rows"
      :fields="fields"
      v-if="!loading && rows.length > 0"
    >
    </b-table>
    <b-alert variant="warning" :show="!loading && rows.length === 0">
      Parece que ninguem emitiu um certificado para seu laboratório ainda.
    </b-alert>
  </div>
</template>

<script>
export default {
  name: 'CertificatesListLab',
  watch: {
    url(nv, ov) {
      if (nv !== ov) {
        this.getList()
      }
    }
  },

  data: () => ({
    loading: false,
    rows: [],
    fields: [
      {
        key: 'store',
        label: 'Ótica'
      },
      {
        key: 'id',
        label: 'ID'
      },
      {
        key: 'number',
        label: 'Número Pedido'
      },
      {
        key: 'user',
        label: 'Emitido por'
      },
      {
        key: 'created_at',
        label: 'emitido em'
      },
      {
        key: 'expires_at',
        label: 'valído até'
      },
      {
        key: 'created_at',
        label: 'Usando desde'
      },
      {
        key: 'cost',
        label: 'Custo'
      }
    ]
  }),

  computed: {
    url() {
      return this.$route.fullPath
    },
    user: {
      get() {
        return this.$store.state.auth.user
      },
      set(user) {
        this.$store.dispatch('auth/setUser', user)
      }
    }
  },

  methods: {
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },

    formatDate(date) {
      if (date.includes('T')) {
        let splited = date.split('T')
        return splited[0].split('-').reverse().join('-')
      }

      let splited = date.split(' ')
      return splited[0].split('-').reverse().join('-')
    },

    getList() {
      this.loading = true
      this.rows = []
      this.$http
        .get(this.url)
        .then((res) => {
          this.rows = res.data.data.map((row) => {
            return {
              store: row.store.name,
              id: row?.order?.data?.id,
              number: row?.order?.number,
              user: row?.user?.name,
              created_at: this.formatDate(row.created_at),
              expires_at: this.formatDate(row.expires_at),
              credits: row.credits,
              cost: `R$ ${row.cost}`
            }
          })
        })
        .finally(() => (this.loading = false))
    },

    change(e) {
      this.pagination = e.pagination
      this.getList()
    }
  },

  mounted() {
    this.getList()
  }
}
</script>
