export function GET_ORDERS(state) {
  return state.orders
}
export function GET_ORDER(state) {
  return state.order
}

export function GET_ORDERS_TOTAL(state) {
  return state.total
}

export function GET_PRISM(state) {
  return state.hasPrism
}

export function GET_MARK(state) {
  return state.hasMark
}

export function GET_LOADER(state) {
  return state.loading
}

export function getNewOrder(state) {
  return state.newOrder
}
