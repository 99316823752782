<template>
  <b-button-group class="w-100 mb-5" style="margin-bottom: 0px !important">
    <b-button variant="success" style="width: 33.333%">
      OS {{ getOrigin(order) }} <strong>{{ getOrderNumber(order) }} </strong
      ><br />
      {{ getPurchasedDate(order) }}
    </b-button>
    <b-button variant="info" style="width: 33.333%">
      <strong>FATURADO</strong><br />
      {{ getCloseDate(order) }}
    </b-button>
    <b-button variant="warning" style="width: 33.333%">
      ID
      <strong>{{ order.data.id }} </strong><br />
      {{ getIssueDate(order) }}
    </b-button>
  </b-button-group>
</template>

<script>
export default {
  name: 'OrderListGroup',

  props: {
    order: {
      type: Object,
      required: true
    }
  },

  methods: {
    getPurchasedDate(order) {
      if (order.data.orderPurchaseDate) {
        return order.data.orderPurchaseDate.split('-').reverse().join('/')
      }
      return ''
    },

    getOrigin(order) {
      if (order.details) {
        if (order.details.origin) {
          if (order.details.origin.description) {
            return order.details.origin.description
          }
        }
      }
      return ''
    },

    getOrderNumber(order) {
      if (order.data) {
        if (order.data.orderPurchase) {
          return order.data.orderPurchase
        }
      }
      return false
    },

    getIssueDate(order) {
      if (order.data.issueDate) {
        return order.data.issueDate.split('-').reverse().join('/')
      }
      return ''
    },

    getCloseDate(order) {
      if (order.data.closedDate && order.data.issueDate) {
        return order.data.closedDate.split('-').reverse().join('/')
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped></style>
