<template>
  <div style="margin-top: 30px">
    <div class="text-center" v-if="loading && lenses.length === 0">
      <b-spinner label="Spinning"></b-spinner>
    </div>
    <div v-else>
      <div style="margin-top: 20px"><h2>Escolha o modelo da armação:</h2></div>
      <div class="carrousel">
        <Lens v-for="lens in lenses" :key="`lens${lens.id}`" :lens="lens" />
      </div>
    </div>
  </div>
</template>

<script>
import Lens from './Lens'

export default {
  name: 'LensesCarroussel',

  components: { Lens },

  data: () => ({
    lenses: [],
    loading: false
  }),

  methods: {
    getLenses() {
      this.loading = true
      this.$http
        .get('lenses')
        .then(({ data }) => (this.lenses = data))
        .finally(() => (this.loading = false))
    }
  },

  mounted() {
    this.getLenses()
  }
}
</script>

<style lang="sass" scoped>
.carrousel
  position: absolute
  left: 0px
  width: 100%
  overflow: scroll
  overflow-y: hidden
  white-space: nowrap
  padding-top: 12px
  padding-bottom: 14px
  padding-left: 13px
  margin-bottom: 100px

  height: 140px
</style>
