<template>
  <b-form-select
    v-model="selected"
    :options="months"
    @change="emitChanged"
  ></b-form-select>
</template>

<script>
export default {
  name: 'MonthSwitcher',

  data: () => ({
    selected: null
  }),

  computed: {
    months() {
      this.currentMonth()
      return [...Array(12).keys()].map((m) => {
        let date = new Date(1976, m, 13)
        return {
          value: m + 1,
          text: date.toLocaleString('pt-br', { month: 'long' })
        }
      })
    }
  },

  methods: {
    currentMonth() {
      let date = new Date()
      this.selected = date.getMonth() + 1
    },

    emitChanged(month) {
      this.$emit('input', month)
    }
  },

  mounted() {
    this.emitChanged(this.selected)
  }
}
</script>

<style lang="scss">
h3 {
  font-size: 1.7em !important;
  font-weight: bold !important;
}
.custom-select {
  background: #ffffff33;
  border: none !important;
  color: #000 !important;
  font-weight: bold !important;
}
</style>
