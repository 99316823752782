<template>
  <div>
    <div v-if="list.length > 0">
      <div
        v-for="(order, k) in list"
        :key="k"
        flat
        @click="openOrder(order)"
        :class="{ disabled: order.data.status !== 'INVOICED' }"
      >
        <b-card img-alt="Image" img-top class="mb-2">
          <div class="row">
            <div class="col">
              <OrderListGroup :order="order" />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <OrderCustomerEdit
                :orderId="order.id"
                :name="getCustomerName(order)"
                :document="getCustomerDocument(order)"
                :phone="getCustomerPhone(order)"
                :email="getCustomerEmail(order)"
                @save="setCustomerName"
              />
              <h3 class="mt-5">
                <strong>{{ getCustomerName(order) }}</strong>
              </h3>
              {{ order.laboratory.name }}<br />
              <div v-if="order.category">
                {{ order.category.name }}
              </div>
            </div>
            <div class="d-flex justify-content-center align-items-center"></div>
          </div>
        </b-card>
      </div>
    </div>
    <div class="alert alert-warning" v-else>
      <strong>Desculpe!</strong> Não existe nenhum resultado para sua busca.
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import OrderListGroup from './OrderListGroup'
import OrderCustomerEdit from './OrderCustomerEdit'

export default {
  name: 'OrdersList',

  components: { OrderListGroup, OrderCustomerEdit },

  data: () => ({
    loading: false
  }),

  computed: {
    orders() {
      return this.$store.state.orders.orders
    },

    list() {
      return this.orders
    }
  },

  mounted() {
    this.loading = true
    if (this.orders.length === 0) {
      this.$router.push('/dashboard')
    }
    this.fetchOrders({
      month: this.$route.query.month,
      year: this.$route.query.year
    }).then(() => {
      this.loading = false
    })
  },

  methods: {
    ...mapActions('orders', ['fetchOrders']),

    setCustomerName(customer) {
      this.name = customer.name
    },

    getCustomerName(order) {
      if (order.data) {
        if (order.data.orderMembers) {
          if (order.data.orderMembers.length > 0) {
            const customer = order.data.orderMembers.filter(
              (item) => item.orderMemberType.description === 'PACIENTE'
            )
            if (customer.length == 1) {
              return customer[0].name
            }
          }
        }
      }

      if (order.customer) {
        if (order.customer.name) {
          return order.customer.name
        }
      }

      return 'SEM NOME'
    },

    getCustomerDocument(order) {
      if (order.customer) {
        if (order.customer.document) {
          return order.customer.document
        }
      }
      return null
    },

    getCustomerEmail(order) {
      if (order.customer) {
        if (order.customer.email) {
          return order.customer.email
        }
      }
      return null
    },

    getCustomerPhone(order) {
      if (order.customer) {
        if (order.customer.phone) {
          return order.customer.phone
        }
      }
      return null
    },

    async openOrder(order) {
      if (order.data.status === 'INVOICED') {
        this.$router.push(`/ordens/${order.id}`)
      }

      return false
    }
  }
}
</script>

<style lang="css">
.disabled {
  opacity: 0.6;
}
.card-body {
  padding: 2rem;
}
</style>
