<template>
  <div v-if="loading" class="text-center">carregando...</div>
  <div class="card card-custom card-stretch gutter-b" v-else>
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          Certificados Emitidos
        </span>
        <span class="text-muted mt-3 font-weight-bold font-size-sm">
          127 Clientes Cadastrados
        </span>
      </h3>
      <div class="card-toolbar">
        <ul class="nav nav-pills nav-pills-sm nav-dark-75">
          <li class="nav-item">
            <a
              class="nav-link py-2 px-4"
              :class="{ active: this.show === 'month' }"
              data-toggle="tab"
              href="#kt_tab_pane_3_1"
              @click="fetchPeriod('month')"
            >
              Mês
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link py-2 px-4"
              data-toggle="tab"
              :class="{ active: this.show === 'week' }"
              href="#kt_tab_pane_2_1"
              @click="fetchPeriod('week')"
            >
              Semana
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link py-2 px-4"
              data-toggle="tab"
              :class="{ active: this.show === 'day' }"
              href="#kt_tab_pane_1_1"
              @click="fetchPeriod('day')"
            >
              Dia
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="card-body pt-3 pb-0">
      <div class="table-responsive">
        <table class="table table-borderless table-vertical-center">
          <thead>
            <tr>
              <th
                v-for="(column, idx) in columns"
                :key="idx"
                :class="getClass(idx)"
              >
                {{ column }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) in rows">
              <tr v-bind:key="i">
                <td class="pl-0 py-4">
                  <div
                    class="symbol symbol-50 symbol-light mr-1"
                    style="display: flex; aligh-items: center"
                  >
                    <div
                      :style="`background:url(${item.store.logo}) center center no-repeat;width: 60px; height: 60px;border-radius: 10px; border:1px solid #ccc`"
                    >
                      &nbsp;
                    </div>
                    <div
                      style="
                        display: flex;
                        aligh-items: center;
                        margin-left: 20px;
                      "
                    >
                      <div>
                        <a
                          class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                        >
                          {{ item.store.name }}
                        </a>
                        <div>
                          <span class="font-weight-bolder">Email:</span>
                          <a
                            class="text-muted font-weight-bold text-hover-primary"
                          >
                            {{ item.store.email }}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="pl-0 py-4">
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.api }}
                  </span>
                </td>
                <td class="text-right">
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.api_total }}
                  </span>
                </td>

                <td class="pl-0 py-4 text-right">
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.certs }}
                  </span>
                </td>
                <td class="text-right" v-if="user.is_admin">
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.certs_total }}
                  </span>
                </td>

                <td class="text-right pr-0" v-if="user.is_admin">
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.total }}
                  </span>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DashboardTable',

  data: () => ({
    show: 'week',
    loading: false,
    columns: [],
    rows: [],
    pagination: null
  }),

  computed: {
    ...mapGetters(['store']),

    user() {
      return this.$store.state.auth.user
    }
  },

  methods: {
    getClass(idx) {
      if (idx >= 2) {
        return {
          'pl-0': true,
          'py-4': true,
          'text-right': true
        }
      }
      return {
        'pl-0': true,
        'py-4': true
      }
    },
    fetchPeriod(val) {
      this.show = val
      this.getRows()
    },

    getRows() {
      this.loading = true
      this.$http
        .query('/stores/dashboard', {
          params: {
            month: this.show === 'month' ? 1 : null,
            day: this.show === 'day' ? 1 : null,
            week: this.show === 'week' ? 1 : null
          }
        })
        .then(({ data }) => {
          this.columns = data.data.columns
          this.rows = data.data.rows
          this.pagination = data.meta
        })
        .finally(() => (this.loading = false))
    }
  },

  mounted() {
    this.getRows()
  }
}
</script>
