<template>
  <div>
    <q-card class="q-pa-lg q-ma-md">
      <div class="text-h4">{{ title }}</div>
      <q-separator /><br />
      <form
        @submit.prevent.stop="onSubmit"
        @reset.prevent.stop="onReset"
        class="q-gutter-md"
      >
        <q-input
          ref="name"
          filled
          v-model="name"
          label="Razão Social *"
          lazy-rules
          :rules="rules.name"
        />

        <q-input
          ref="contact"
          filled
          v-model="contact"
          label="Nome responsável *"
          lazy-rules
          :rules="rules.contact"
        />

        <q-input
          ref="email"
          filled
          v-model="contact"
          label="Email responsável *"
          lazy-rules
          :rules="rules.email"
        />

        <q-input
          ref="document"
          filled
          v-model="document"
          label="CNPJ *"
          lazy-rules
          mask="##.###.###/####-##"
          :rules="rules.document"
        />

        <div>
          <q-btn label="Salvar" type="submit" color="primary" />
          <q-btn
            label="Cancelar"
            color="primary"
            flat
            class="q-ml-sm"
            @click="$router.push('/fabricantes')"
          />
        </div>
      </form>
    </q-card>
  </div>
</template>

<script>
export default {
  name: 'Laboratoryform',

  data() {
    return {
      title: 'Cadastrar Laboratório',
      id: null,
      name: null,
      contact: null,
      document: null,
      email: null,
      loading: false,
      rules: {
        name: [
          (val) =>
            (val && val.length > 0) ||
            'Por favor digite a razão social do laboratório'
        ],
        contact: [
          (val) =>
            (val && val.length > 0) ||
            'Por favor digite o nome da pessoa que irá usar sistema no laboratório'
        ],
        email: [
          (val) =>
            (val && val.length > 0) ||
            'Por favor digite o email da pessoa que irá usar sistema no laboratório'
        ],
        document: [
          (val) =>
            (val && val.length > 0) || 'Por favor digite o cnpj do laboratório'
        ]
      }
    }
  },

  computed: {
    params() {
      return {
        name: this.name
      }
    }
  },

  methods: {
    onSubmit() {
      this.$refs.name.validate()
      this.$refs.contact.validate()
      this.$refs.email.validate()
      this.$refs.document.validate()

      if (
        this.$refs.name.hasError ||
        this.$refs.contact.hasError ||
        this.$refs.email.hasError ||
        this.$refs.document.hasError
      ) {
        return true
      }
      this.save()
    },

    save() {
      this.$loading.show()

      const config = {
        method: this.id ? 'PUT' : 'POST',
        url: this.id ? `/manufacturers/${this.id}` : '/manufacturers',
        data: this.params
      }

      this.$axios(config)
        .then((res) => {
          this.$q.notify({
            progress: true,
            timeout: 2000,
            position: 'top',
            message: res.data.msg,
            color: 'secondary',
            multiLine: true,
            avatar: '/favicon.png'
          })
          this.$router.push('/fabricantes')
        })
        .finally(() => this.$loading.hide())
    },

    getManufacturer() {
      this.$loading.show()
      this.$axios
        .get(`/products/${this.id}`)
        .then((res) => {
          const data = res.data
          this.id = data.id
          this.title = data.name
          this.name = data.name
        })
        .finally(() => this.$loading.hide())
    },

    async getManufacturers() {
      this.loading = true
      await this.$axios
        .get(`/manufacturers/?all=1`)
        .then((res) => (this.items = res.data))
        .finally(() => (this.loading = false))
    },

    onReset() {
      this.name = null
      this.category_id = null
      this.manufacturer_id = null
      this.$refs.name.resetValidation()
      this.$refs.manufacturer_id.resetValidation()
      this.$refs.category_id.resetValidation()
    }
  },

  beforeMount() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id
      this.getManufacturer()
    }
  }
}
</script>
