export function SET_TECHNOLOGY(state, payload) {
  state.technology = payload
}
export function RESET_STATE(state, payload) {
  state.has_products = payload.has_products
  state.loading = payload.loading
  state.type = payload.type
  state.index = payload.index
  state.technology = payload.technology
  state.color = payload.color
  state.manufacturer = payload.manufacturer
  state.treatment = payload.treatment
  state.hoop = payload.hoop
  state.category = payload.category
  state.products = payload.products
  state.panels = payload.panels
  state.apis = payload.apis
}

export function PUSH_MANUFACTURER(state, payload) {
  state.manufacturer[0].unshift(payload)
}
export function SET_TYPE(state, payload) {
  state.type = payload
}

export function SET_MANUFACTURER(state, payload) {
  state.manufacturer = payload
}

export function SET_INDEX(state, payload) {
  state.index = payload
}

export function SET_TREATMENT(state, payload) {
  state.treatment = payload
}

export function SET_HOOP(state, payload) {
  state.hoop = payload
}

export function SET_PRODUCTS(state, payload) {
  state.products = payload
}

export function SET_COLOR(state, payload) {
  state.color = payload
}

export function SET_LOADER(state, payload) {
  state.loading = payload
}

export function SET_HAS_PRODUCTS(state, payload) {
  state.has_products = payload
}
export function CLEAR_PANELS(state) {
  state.panels = []
}

export function PUSH_PANELS(state, payload) {
  state.panels.push(payload)
}

export function SET_APIS(state, payload) {
  state.apis = payload
}

export function REMOVE_PANEL(state, payload) {
  state.panels.filter((panel, key) => {
    if (panel.action === payload.action) {
      state.panels.splice(key, 1)
    }
  })
}
