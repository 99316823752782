import './core/plugins/bootstrap-vue'
import './core/plugins/inline-svg'
import './core/plugins/metronic'
import './core/plugins/formvalidation'
import 'vue-loading-overlay/dist/vue-loading.css'

import Vue from 'vue'
import ApiService from './core/services/api.service'
import App from './App.vue'
import { RESET_LAYOUT_CONFIG } from './core/services/store/config.module'
import { VERIFY_AUTH } from './core/services/store/auth.module'
import './core/config/autoload.config'
import router from './router'
import store from './core/services/store'
import Loading from 'vue-loading-overlay'
import VueLocalForage from 'vue-localforage'
import ImageUploader from 'vue-image-upload-resize'
import qCard from './components/qCard.vue'
import qCardSection from './components/qCardSection.vue'
import VueSimpleAlert from 'vue-simple-alert'
import VueMoment from 'vue-moment'

const moment = require('moment')

require('moment/locale/pt')

Vue.use(VueMoment, {
  moment
})

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)
Vue.use(ImageUploader)
Vue.use(VueSimpleAlert)

Vue.use(VueLocalForage)
Vue.use(Loading)
Vue.component('q-card', qCard)
Vue.component('q-card-section', qCardSection)
Vue.config.productionTip = false
ApiService.init()

router.beforeEach((to, from, next) => {
  Promise.all([store.dispatch(VERIFY_AUTH)]).then(next)
  store.dispatch(RESET_LAYOUT_CONFIG)
})

Vue.prototype.$http = ApiService

new Vue({
  router,
  store,
  // vuetify,
  render: (h) => h(App)
}).$mount('#app')
