<template>
  <svg
    width="140px"
    viewBox="0 0 309 82"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
  >
    <g transform="matrix(1,0,0,1,-145.1,-53.9)">
      <path
        d="M226.8,135.6L156.7,135.6L156.7,130.5L223.1,120L214,65.4L226.8,65.4L226.8,135.6Z"
        style="fill: white; fill-opacity: 0.5; fill-rule: nonzero"
      />
    </g>
    <g transform="matrix(1,0,0,1,-145.1,-53.9)">
      <path
        d="M226.8,124.2L156.6,135.5L150.3,96.8C151.5,93.4 152,90.7 152.7,89.8C152.7,89.8 153.5,88.8 154.9,88.4L155.1,88.4C155.4,
        88.3 155.7,88.3 156.1,88.3C156.5,88.3 156.8,88.3 157.1,88.4L157.3,88.4C158.8,88.8 159.5,89.8 159.5,
        89.8C161.7,92.6 162.8,115.3 187.4,114.8C214.4,114.2 207.7,90.4 211.9,86.7C211.9,86.7 212.7,85.5 213.9,85.5L214.5,
        78.7C214.5,78.7 196.7,76.2 188,76.4C171,76.8 165,81 157.6,81L154.5,81C152.2,
        81 150,80.6 147.5,80L145.1,65.2L215.3,53.9L226.8,124.2ZM186.8,112.7C199.3,
        112.7 205.7,104.8 206.4,96C207.2,86.8 206.5,79.3 186.8,79.3C167.1,79.3 164.4,87 165.7,94.4C167.3,103.7 174.4,112.7 186.8,112.7Z"
        style="fill: white; fill-rule: nonzero"
      />
    </g>
    <g transform="matrix(1,0,0,1,-145.1,-53.9)">
      <path
        d="M240.7,98.3C240.7,92.5 245.2,87.8 251.5,87.8C257.7,87.8 262.2,92.4 262.2,98.2L262.2,
        98.3C262.2,104 257.7,108.8 251.4,108.8C245.2,108.7 240.7,104.1 240.7,98.3ZM257.5,98.3C257.5,
        94.8 255,91.9 251.4,91.9C247.8,91.9 245.3,94.7 245.3,98.2L245.3,98.3C245.3,101.8 247.8,
        104.7 251.4,104.7C255,104.6 257.5,101.8 257.5,98.3Z"
        style="fill: white; fill-rule: nonzero"
      />
    </g>
    <g transform="matrix(1,0,0,1,-145.1,-53.9)">
      <path
        d="M266,88.2L274.3,88.2C279.1,88.2 282,91.1 282,95.2L282,95.3C282,100 278.4,102.4 273.8,102.4L270.4,
        102.4L270.4,108.5L266,108.5L266,88.2ZM274,98.4C276.2,98.4 277.5,97.1 277.5,
        95.3L277.5,95.2C277.5,93.2 276.1,92.1 273.9,92.1L270.5,92.1L270.5,98.3L274,98.4Z"
        style="fill: white; fill-rule: nonzero"
      />
    </g>
    <g transform="matrix(1,0,0,1,-145.1,-53.9)">
      <path
        d="M290.2,92.3L284,92.3L284,88.2L300.7,88.2L300.7,92.3L294.6,92.3L294.6,108.4L290.2,
        108.4L290.2,92.3Z"
        style="fill: white; fill-rule: nonzero"
      />
    </g>
    <g transform="matrix(1,0,0,1,-145.1,-53.9)">
      <rect
        x="303.7"
        y="88.2"
        width="4.4"
        height="20.2"
        style="fill: white; fill-rule: nonzero"
      />
    </g>
    <g transform="matrix(1,0,0,1,-145.1,-53.9)">
      <path
        d="M312.4,98.3C312.4,92.5 316.7,87.8 322.5,87.8C326.1,87.8 328.3,89.1 330.4,91L329.3,
        92.1C327.5,90.4 325.5,89.1 322.5,89.1C317.6,89.1 314,93.1 314,98.1L314,98.2C314,
        103.3 317.7,107.3 322.5,107.3C325.4,107.3 327.4,106.2 329.5,104.2L330.5,105.2C328.4,
        107.3 326.1,108.7 322.5,108.7C316.6,108.7 312.4,104.1 312.4,98.3Z"
        style="fill: white; fill-rule: nonzero"
      />
    </g>
    <g transform="matrix(1,0,0,1,-145.1,-53.9)">
      <path
        d="M332.8,98.3C332.8,92.7 336.9,87.8 343,87.8C349.1,87.8 353.1,92.7 353.1,
        98.2L353.1,98.3C353.1,103.8 349,108.8 342.9,108.8C336.8,108.7 332.8,103.8 332.8,
        98.3ZM351.6,98.3C351.6,93.2 348,89.1 343,89.1C338,89.1 334.4,93.1 334.4,98.1L334.4,
        98.2C334.4,103.2 338,107.3 343,107.3C348,107.4 351.6,103.3 351.6,98.3Z"
        style="fill: white; fill-rule: nonzero"
      />
    </g>
    <g transform="matrix(1,0,0,1,-145.1,-53.9)">
      <path
        d="M358.1,88.2L359.5,88.2L373.4,105.8L373.4,88.2L374.8,88.2L374.8,108.4L373.7,108.4L359.5,
        90.4L359.5,108.4L358.1,108.4L358.1,88.2Z"
        style="fill: white; fill-rule: nonzero"
      />
    </g>
    <g transform="matrix(1,0,0,1,-145.1,-53.9)">
      <path
        d="M380.8,88.2L395.2,88.2L395.2,89.6L382.3,89.6L382.3,97.5L393.9,97.5L393.9,98.9L382.3,
        98.9L382.3,107L395.3,
        107L395.3,108.4L380.8,108.4L380.8,88.2Z"
        style="fill: white; fill-rule: nonzero"
      />
    </g>
    <g transform="matrix(1,0,0,1,-145.1,-53.9)">
      <path
        d="M398.2,98.3C398.2,92.5 402.5,87.8 408.3,87.8C411.9,87.8 414.1,89.1 416.2,91L415.1,
        92.1C413.3,90.4 411.3,89.1 408.3,89.1C403.4,89.1 399.8,93.1 399.8,98.1L399.8,98.2C399.8,
        103.3 403.5,107.3 408.3,107.3C411.2,107.3 413.2,106.2 415.3,104.2L416.3,105.2C414.2,
        107.3 411.9,108.7 408.3,108.7C402.5,108.7 398.2,104.1 398.2,98.3Z"
        style="fill: white; fill-rule: nonzero"
      />
    </g>
    <g transform="matrix(1,0,0,1,-145.1,-53.9)">
      <path
        d="M425.9,89.6L418.8,89.6L418.8,88.2L434.4,88.2L434.4,89.6L427.4,89.6L427.4,
        108.4L425.9,108.4L425.9,89.6Z"
        style="fill: white; fill-rule: nonzero"
      />
    </g>
    <g transform="matrix(1,0,0,1,-145.1,-53.9)">
      <path
        d="M443.1,88L444.5,88L453.9,108.4L452.3,108.4L449.7,102.7L437.8,102.7L435.2,
        108.4L433.6,108.4L443.1,88ZM449.1,101.4L443.8,89.7L438.5,101.4L449.1,101.4Z"
        style="fill: white; fill-rule: nonzero"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'logo',

  props: {
    width: {
      type: String,
      default: '30px'
    },
    color: {
      type: String,
      default: '35,31,32'
    }
  },

  computed: {
    style() {
      return `fill:rgb(${this.color});fill-rule:nonzero;`
    }
  }
}
</script>

<style lang="scss" scoped></style>
