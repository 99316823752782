<template>
  <div>
    <q-input
      color="purple-12"
      label="Nome"
      v-model="name"
      :rules="[(name) => !!name || 'Nome é obrigatórios']"
    >
      <template v-slot:prepend>
        <q-icon name="business" />
      </template>
    </q-input>

    <q-input
      color="purple-12"
      label="CNPJ"
      v-model="document"
      :rules="[(document) => !!document || 'CNPJ é obrigatórios']"
    >
      <template v-slot:prepend>
        <q-icon name="business" />
      </template>
    </q-input>
    <q-input
      color="purple-12"
      label="Contato"
      v-model="contact"
      :rules="[(contact) => !!contact || 'Contato é obrigatórios']"
    >
      <template v-slot:prepend>
        <q-icon name="face" />
      </template>
    </q-input>

    <q-input
      color="purple-12"
      label="Celular"
      v-model="phone"
      mask="(##) ####-#####"
      fill-mask
      :rules="[(phone) => !!phone || 'Celular é obrigatório']"
    >
      <template v-slot:prepend>
        <q-icon name="phone" />
      </template>
    </q-input>

    <q-input
      color="purple-12"
      label="Email"
      v-model="email"
      :rules="[(email) => !!email || 'Email é obrigatório']"
    >
      <template v-slot:prepend>
        <q-icon name="email" />
      </template>
    </q-input>

    <q-input
      color="purple-12"
      label="Senha"
      v-model="password"
      :rules="[(password) => !!password || 'Senha é obrigatório']"
    >
      <template v-slot:prepend>
        <q-icon name="lock" />
      </template>
    </q-input>

    <q-input
      color="purple-12"
      label="Confirmar Senha"
      v-model="password_confirm"
      :rules="[
        (password_confirm) =>
          !!password_confirm || 'Confirmar senha é obrigatório'
      ]"
    >
      <template v-slot:prepend>
        <q-icon name="lock" />
      </template>
    </q-input>

    <div class="row q-mt-lg">
      <q-btn
        :loading="loading"
        class="full-width"
        :size="'lg'"
        dense
        color="primary"
        expanded
        :label="'CADASTRAR'"
        @click="save"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'InviteForm',
  data() {
    return {
      id: null,
      loading: false,
      name: null,
      contact: null,
      email: null,
      document: null,
      phone: null,
      password: null,
      password_confirm: null
    }
  },

  computed: {
    profile() {
      return this.$store.state.auth
    },

    inviteId() {
      return this.$route.params.id
    },

    invite() {
      return {
        id: this.id,
        name: this.name,
        contact: this.contact,
        email: this.email,
        phone: this.phone,
        document: this.document,
        password: this.password,
        password_confirm: this.password_confirm
      }
    }
  },

  methods: {
    save() {
      this.loading = true
      this.$axios
        .post('/invite', this.invite)
        .then((res) => {
          this.$store.dispatch('auth/login', res.data)
          this.$router.push('/dashboard')
          this.loading = false
        })
        .finally(() => (this.loading = false))
    },

    getInvite() {
      this.$loading.show()
      this.$axios
        .get(`/invite/${this.inviteId}`)
        .then((res) => {
          const data = res.data
          this.id = data.id
          this.name = data.name
          this.contact = data.contact
          this.email = data.email
          this.phone = data.phone
        })
        .catch(() => {
          this.$q.notify({
            progress: true,
            timeout: 2000,
            position: 'top',
            message: 'Convite não está mais disponível',
            color: 'primaru',
            multiLine: true,
            avatar: '/favicon.png'
          })

          this.$router.push('/')
        })
        .finally(() => this.$loading.hide())
    }
  },

  mounted() {
    this.getInvite()
  }
}
</script>
