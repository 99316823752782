<template>
  <div>
    <div
      class="preview"
      :style="`background-color: ${color};background-image: url(/media/mask.png)`"
    >
      <div
        class="image"
        :style="`background-image: url(${image}); background-size: 340px 262px;  background-repeat: no-repeat; background-position: 0 50%; `"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CertificatePreview',

  props: {
    color: {
      type: String,
      default: '#CDC387'
    },
    image: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.preview {
  width: 350px;
  height: 511px;
}
.image {
  width: 338px;
  height: 131px;
  margin: 0 auto;
  position: relative;
  top: 73px;
}
</style>
