<template>
  <div>
    <b-modal ref="my-modal" @ok="accepted" @cancel="$router.push('/login')">
      <div v-if="loading" class="text-center">
        <div
          class="spinner spinner-primary mx-auto"
          style="width: 40px; margin: 0 auto"
        ></div>
      </div>
      <div v-else class="my-4" v-html="terms"></div>
    </b-modal>
    <label class="checkbox mb-0">
      <input type="checkbox" name="agree" v-model="agree" />
      <span class="mr-2"></span>
      Eu aceito os
      <a href="#" class="ml-2" @click.prevent.stop="showModal"
        >termos e condições</a
      >.
    </label>
  </div>
</template>

<script>
export default {
  name: 'Terms',

  data: () => ({
    agree: false,
    loading: false,
    terms: false
  }),

  watch: {
    agree(val) {
      this.$emit('accepted', val)
    }
  },

  methods: {
    showModal() {
      this.fetchTerms()
      this.$refs['my-modal'].show()
    },

    hideModal() {
      this.$refs['my-modal'].hide()
    },

    toggleModal() {
      this.$refs['my-modal'].toggle('#toggle-btn')
    },

    accepted() {
      this.agree = true
      this.$emit('accepted', true)
    },

    fetchTerms() {
      this.loading = true
      fetch('/terms.txt')
        .then((res) => res.text())
        .then((terms) => {
          this.terms = terms
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped></style>
