<template>
  <div>
    <q-table
      :data="rows"
      :row-key="(row) => row.id"
      :loading="loading"
      :pagination.sync="pagination"
      @request="change"
      @row-click="open"
    >
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="id" :props="props" v-if="props.row.id">
            {{ props.row.id }}
          </q-td>
          <q-td key="name" :props="props" v-if="props.row.name">
            {{ props.row.name }}
          </q-td>
          <q-td key="manufacturer" :props="props" v-if="props.row.manufacturer">
            {{ props.row.manufacturer }}
          </q-td>
          <q-td key="document" :props="props" v-if="props.row.document">
            {{ props.row.document }}
          </q-td>
          <q-td key="category" :props="props" v-if="props.row.category">
            {{ props.row.category }}
          </q-td>
          <q-td key="image" :props="props" v-if="props.row.image">
            <img :src="props.row.image" style="width: 80px" />
          </q-td>
          <q-td key="logo" :props="props" v-if="props.row.logo">
            <img :src="props.row.logo" style="width: 80px" />
          </q-td>
          <q-td
            key="color"
            :props="props"
            v-if="props.row.color"
            :style="`background-color:rgb(${props.row.color})`"
          >
            {{ props.row.color }}
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script>
export default {
  name: 'ResourceList',

  props: {
    endpoint: {
      type: String,
      default: '',
      required: true
    }
  },

  data() {
    return {
      loading: false,
      rows: [],
      pagination: {
        sortBy: null,
        page: null,
        descending: null,
        rowsPerPage: 50,
        rowsNumber: null
      }
    }
  },

  computed: {
    url() {
      if (this.pagination.page) {
        return `${this.$route.meta.endpoint}/?page=${this.pagination.page}`
      }
      return this.$route.meta.endpoint
    }
  },

  methods: {
    getList() {
      this.loading = true
      this.rows = []
      this.$axios
        .get(this.url)
        .then((res) => {
          this.rows = res.data.data
          this.pagination.page = res.data.pagination.current_page
          this.pagination.rowsPerPage = res.data.pagination.per_page
          this.pagination.rowsNumber = res.data.pagination.total
        })
        .finally(() => (this.loading = false))
    },

    change(e) {
      this.pagination = e.pagination
      this.getList()
    },

    open(e, row) {
      this.$router.push(`${this.$route.path}/${row.id}`)
    }
  },

  mounted() {
    this.getList()
  }
}
</script>
