<template>
  <q-page padding>
    <div class="text-h4 q-mb-md">{{ title }}</div>
    <br />
    <q-card class="q-pa-md">
      <q-input
        v-for="(item, key) in items"
        :key="key"
        :label="item"
        v-model="data[item]"
      />

      <q-card-actions>
        <q-btn
          color="standard"
          label="Cancelar"
          text-color="black"
          @click="cancel"
        />
        <q-btn color="primary" label="Salvar" @click="save" />
      </q-card-actions>
    </q-card>
  </q-page>
</template>

<script>
export default {
  name: 'ResourceForm',

  data() {
    return {
      title: '',
      items: [],
      data: {}
    }
  },

  computed: {
    url() {
      if (this.$route.params.id) {
        return `${this.$route.meta.endpoint}/${this.$route.params.id}`
      }
      return this.$route.meta.endpoint
    }
  },

  methods: {
    save() {
      if (this.$route.params.id) {
        this.update()
      } else {
        this.insert()
      }
    },

    update() {
      this.$loading.show()
      this.$axios
        .put(this.url, this.data)
        .then((res) => {
          this.data = res.data
          this.cancel()
        })
        .catch((res) => {
          const err = Object.keys(res.response.data.errors).map((item) => {
            return res.response.data.errors[item]
          })

          // const dismiss = window.$q.notify();
          // dismiss();
          this.$q.notify({
            progress: true,
            timeout: 2000,
            multiline: 2000,
            position: 'top',
            message: err.join(', '),
            color: 'negative',
            multiLine: true,
            avatar: '/favicon.png'
          })
        })
        .finally(() => this.$loading.hide())
    },

    insert() {
      this.$loading.show()
      this.$axios
        .post(this.url, this.data)
        .then(() => {
          this.cancel()
        })
        .finally(() => this.$loading.hide())
    },

    getResource() {
      this.$loading.show()
      this.$axios
        .get(this.url)
        .then((res) => {
          this.title = res.data.name
          this.items = Object.keys(res.data)
          this.data = res.data
        })
        .finally(() => this.$loading.hide())
    },

    cancel() {
      this.$router.push(this.$route.matched[0].path)
    }
  },

  mounted() {
    if (this.$route.params.id) {
      this.getResource()
    } else {
      this.title = `Inserir ${this.$route.meta.title}`
    }
  }
}
</script>
