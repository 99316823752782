<template>
  <div>
    <q-card class="q-pa-lg q-ma-md">
      <div class="text-h4">{{ title }}</div>
      <q-separator /><br />
      <form
        @submit.prevent.stop="onSubmit"
        @reset.prevent.stop="onReset"
        class="q-gutter-md"
      >
        <div class="row">
          <div class="col q-pr-sm">
            <q-select
              ref="manufacturer_id"
              v-model="manufacturerName"
              :options="manufacturers"
              label="Fabricante"
              :loading="loading"
              filled
              :rules="rules.manufacturer"
              @input="setManufacturer"
            />
          </div>
          <div class="col q-pl-sm">
            <q-select
              ref="category_id"
              v-model="categoryName"
              :options="categories"
              label="Família"
              :rules="rules.category"
              filled
              :loading="loading"
              @input="setCategory"
            />
          </div>
        </div>

        <q-input
          ref="name"
          filled
          v-model="name"
          label="Nome *"
          lazy-rules
          :rules="rules.name"
        />

        <div>
          <q-btn label="Salvar" type="submit" color="primary" />
          <q-btn
            label="Cancelar"
            color="primary"
            flat
            class="q-ml-sm"
            @click="$router.push('/produtos')"
          />
        </div>
      </form>
    </q-card>
  </div>
</template>

<script>
export default {
  name: 'Productform',

  data() {
    return {
      title: 'Cadastrar Produto',
      id: null,
      manufacturerName: null,
      categoryName: null,
      manufacturer_id: null,
      manufacturer: null,
      category_id: null,
      categories: [],
      name: null,
      loading: false,
      items: [],
      rules: {
        name: [
          (val) =>
            (val && val.length > 0) || 'Por favor digite o nome do produto'
        ],
        manufacturer: [
          (val) => (val && val.length > 0) || 'Por favor escolha o fabricante'
        ],
        category: [
          (val) =>
            (val && val.length > 0) || 'Por favor escolha a família de produtos'
        ]
      }
    }
  },

  computed: {
    params() {
      return {
        manufacturer_id: this.manufacturer_id,
        category_id: this.category_id,
        name: this.name
      }
    },

    manufacturers() {
      return this.items.map((item) => ({
        label: item.name,
        value: item.id
      }))
    }
  },

  methods: {
    onSubmit() {
      this.$refs.manufacturer_id.validate()
      this.$refs.category_id.validate()
      this.$refs.name.validate()

      if (
        this.$refs.name.hasError ||
        this.$refs.manufacturer_id.hasError ||
        this.$refs.category_id.hasError
      ) {
        return true
      }
      this.save()
    },

    save() {
      this.$loading.show()

      const config = {
        method: this.id ? 'PUT' : 'POST',
        url: this.id ? `/products/${this.id}` : '/products',
        data: this.params
      }

      this.$http(config)
        .then((res) => {
          this.$q.notify({
            progress: true,
            timeout: 2000,
            position: 'top',
            message: res.data.msg,
            color: 'secondary',
            multiLine: true,
            avatar: '/favicon.png'
          })
          this.$router.push('/produtos')
        })
        .finally(() => this.$loading.hide())
    },

    setManufacturer(val) {
      this.manufacturer_id = val.value
      const manufacturer = this.items.filter((item) => (item.id = val.value))[0]
      this.manufacturerName = manufacturer.name
      this.categories = manufacturer.categories.map((item) => ({
        label: item.name,
        value: item.id
      }))
    },

    setCategory(val) {
      this.category_id = val.value
      const category = this.categories.filter(
        (item) => (item.value = val.value)
      )[0]
      this.categoryName = category.label
    },

    getProduct() {
      this.$loading.show()
      this.$http
        .get(`/products/${this.id}`)
        .then((res) => {
          const data = res.data
          this.id = data.id
          this.title = data.name
          this.name = data.name
          this.manufacturer_id = data.manufacturer_id
          this.category_id = data.category_id
          this.manufacturerName = data.manufacturer.name
          this.categoryName = data.category.name
        })
        .finally(() => this.$loading.hide())
    },

    async getManufacturers() {
      this.loading = true
      await this.$http
        .get(`/manufacturers/?all=1`)
        .then((res) => (this.items = res.data))
        .finally(() => (this.loading = false))
    },

    onReset() {
      this.name = null
      this.category_id = null
      this.manufacturer_id = null
      this.$refs.name.resetValidation()
      this.$refs.manufacturer_id.resetValidation()
      this.$refs.category_id.resetValidation()
    }
  },

  beforeMount() {
    this.getManufacturers()
    if (this.$route.params.id) {
      this.id = this.$route.params.id
      this.getProduct()
    }
  }
}
</script>
