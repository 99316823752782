<template>
  <div style="width: 100%">
    <ImageList />
  </div>
</template>

<script>
import ImageList from './ImageList'

export default {
  name: 'ImageUploader',

  components: { ImageList }
}
</script>

<style lang="scss" scoped></style>
