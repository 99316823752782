<template>
  <q-item clickable @click.prevent.stop="goTo">
    <!-- <q-item-section v-if="icon" avatar>
      <q-icon :name="icon" />
    </q-item-section> -->

    <q-item-section>
      <q-item-label
        style="
          font-family: 'poppins' !important;
          font-size: 1.4em;
          margin-left: 20px;
        "
        >{{ title }}</q-item-label
      >
      <q-item-label caption> {{ caption }}</q-item-label>
    </q-item-section>
  </q-item>
</template>

<script>
export default {
  name: 'EssentialLink',

  props: {
    title: {
      type: String,
      required: true
    },

    caption: {
      type: String,
      default: ''
    },

    link: {
      type: String,
      default: '#'
    },

    icon: {
      type: String,
      default: ''
    }
  },

  methods: {
    goTo() {
      if (this.link === 'logout') {
        this.$q.loading.show({
          spinnerColor: 'indigo-14',
          spinnerSize: 100,
          backgroundColor: 'white',
          message: 'Até mais, nos vemos em breve.'
        })

        this.$axios.post('/auth/logout').then(() => {
          setTimeout(() => {
            window.location.href = '/'
            localStorage.clear()
            window.location.reload(true)
          }, 1000)
        })
      } else {
        this.$router.push(this.link)
      }
    }
  }
}
</script>
