<template>
  <AdvancedTableWidget2 style="width: 100%"></AdvancedTableWidget2>
</template>

<script>
import AdvancedTableWidget2 from '@/view/content/widgets/advance-table/Widget2.vue'

export default {
  name: 'LaboratoryDashboard',
  components: {
    AdvancedTableWidget2
  }
}
</script>
