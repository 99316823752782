<template>
  <div>
    <q-input
      color="purple-12"
      label="Nome"
      v-model="name"
      :rules="[(name) => !!name || 'Nome é obrigatórios']"
    >
      <template v-slot:prepend>
        <q-icon name="business" />
      </template>
    </q-input>
    <q-input
      color="purple-12"
      label="Contato"
      v-model="contact"
      :rules="[(contact) => !!contact || 'Contato é obrigatórios']"
    >
      <template v-slot:prepend>
        <q-icon name="face" />
      </template>
    </q-input>
    <q-input
      color="purple-12"
      label="Email"
      v-model="email"
      :rules="[(email) => !!email || 'Email é obrigatório']"
    >
      <template v-slot:prepend>
        <q-icon name="email" />
      </template>
    </q-input>
    <q-input
      color="purple-12"
      label="Celular"
      v-model="phone"
      mask="(##) ####-#####"
      fill-mask
      :rules="[(phone) => !!phone || 'Celular é obrigatório']"
    >
      <template v-slot:prepend>
        <q-icon name="phone" />
      </template>
    </q-input>
    <div class="row q-mt-lg">
      <q-btn
        :loading="loading"
        class="full-width"
        :size="'lg'"
        dense
        color="primary"
        expanded
        :label="'CONVIDAR'"
        @click="save"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'StoreForm',
  data() {
    return {
      loading: false,
      name: null,
      contact: null,
      email: null,
      phone: null
    }
  },

  computed: {
    profile() {
      return this.$store.state.auth
    },

    invite() {
      return {
        name: this.name,
        contact: this.contact,
        email: this.email,
        phone: this.phone
      }
    }
  },

  methods: {
    save() {
      this.loading = true
      this.$axios
        .post('/invite/send', this.invite)
        .then(() => {
          this.$q.notify({
            progress: true,
            timeout: 2000,
            multiline: 2000,
            position: 'top',
            message: 'Convite enviado com sucesso!',
            color: 'secondary',
            multiLine: true,
            avatar: '/favicon.png'
          })
          this.$router.push('/dashboard')
        })
        .finally(() => (this.loading = false))
    }
  }
}
</script>
