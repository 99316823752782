<template>
  <div>
    <b-modal id="modal-1" title="Convidar Usuários" @ok="sendInvitations">
      <SalesmenInvite @input="pushUser" />
    </b-modal>
    <div
      v-if="loading"
      class="d-flex mw-100 justify-content-center align-items-center"
      style="height: 30vh"
    >
      <Loading />
    </div>
    <div v-else>
      <b-button variant="success" class="mb-4" v-b-modal.modal-1
        >convidar</b-button
      >
      <div v-for="(salesman, k) in salesmen" :key="k" flat>
        <b-card img-alt="Image" img-top class="mb-2">
          <div class="row">
            <div class="col">
              <h1>
                <strong>{{ salesman.name }}</strong>
              </h1>
            </div>
          </div>
          <div class="row">
            <div class="col">{{ salesman.email }} <br /></div>
            <div class="col text-right">
              <h1></h1>
            </div>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Loading from '../Loading'
import SalesmenInvite from './SalesmenInvite'

export default {
  name: 'SalesmanList',

  components: { Loading, SalesmenInvite },

  data: () => ({
    users: [],
    isSending: false,
    loading: true
  }),

  computed: {
    ...mapGetters('salesmen', {
      salesmen: 'getSalesmen'
    })
  },

  mounted() {
    this.loading = true
    this.fetchSalesmen().then(() => {
      this.loading = false
    })
  },

  methods: {
    ...mapActions('salesmen', ['fetchSalesmen', 'inviteSalesmen']),

    pushUser(user) {
      this.users.push(user)
    },

    sendInvitations() {
      const params = {
        invites: this.users
      }
      this.inviteSalesmen(params)
    },

    async openOrder(order) {
      await this.$store.dispatch('orders/setOrder', order)
      this.$router.push(`/ordens/${order.id}`)
    }
  }
}
</script>
