<template>
  <b-spinner
    style="width: 4rem; height: 4rem"
    label="Large Spinner"
  ></b-spinner>
</template>

<script>
export default {
  name: 'Loading'
}
</script>

<style lang="scss" scoped>
.loadingContainer {
  position: absolute;
  display: flex;
  height: 100vh;
  margin-top: 300px;
  justify-content: center;
  align-items: center;
}
</style>
