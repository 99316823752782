<template>
  <div>
    <q-card>
      <h2>CERTIFICADO VÁLIDO</h2>
      {{ certificate.customer.name }} <br />
      {{ certificate.customer.email }}
    </q-card>
    <q-card>
      <div v-for="(item, k) in certificate.info" :key="k">
        <div>
          <strong>
            {{ item.label }}
          </strong>
        </div>
        <div class="mb-4">{{ item.value }}</div>
      </div>
    </q-card>
  </div>
</template>

<script>
export default {
  name: 'Prescription',

  props: {
    certificate: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped></style>
