<template>
  <div>
    <div v-if="loading" class="text-center">
      <b-spinner
        style="width: 4rem; height: 4rem"
        label="Large Spinner"
      ></b-spinner>
    </div>
    <b-table
      striped
      hover
      :items="rows"
      v-if="!loading"
      @row-clicked="openCategory"
    ></b-table>
    <b-alert variant="warning" :show="!loading && rows.length === 0">
      Você ainda não possui famílias de produto cadastradas, toque no botão NOVO
      para cadastrar a primeira.
    </b-alert>
  </div>
</template>

<script>
export default {
  name: 'ResourceList',

  watch: {
    url(nv, ov) {
      if (nv !== ov) {
        this.getList()
      }
    }
  },

  data: () => ({
    loading: false,
    rows: []
  }),

  computed: {
    url() {
      return this.$route.fullPath
    }
  },

  methods: {
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },

    openCategory(item) {
      this.$router.push(`/categories/${item.id}`)
    },

    getList() {
      this.loading = true
      this.rows = []
      this.$http
        .get(this.url)
        .then((res) => {
          this.rows = res.data.data.map((row) => {
            return {
              id: row.id,
              nome: row.name,
              marca: row.manufacturer ? row.manufacturer.name : ''
            }
          })
        })
        .finally(() => (this.loading = false))
    },

    change(e) {
      this.pagination = e.pagination
      this.getList()
    }
  },

  mounted() {
    this.getList()
  }
}
</script>
