<template>
  <div class="q-gutter-md">
    <q-select
      outlined
      v-model="model"
      :options="verticalOptions"
      label="Tipo de produto"
      :dense="dense"
    />

    <q-input outlined v-model="text" :dense="dense" label="Eixo">
      <template v-slot:append>
        <q-icon name="calculate" />
      </template>
    </q-input>

    <q-input outlined v-model="text" :dense="dense" label="Cilíndrico">
      <template v-slot:append>
        <q-icon name="calculate" />
      </template>
    </q-input>

    <q-input
      outlined
      v-model="text"
      :dense="dense"
      label="Distancia Intra pupilar"
    >
      <template v-slot:append>
        <q-icon name="calculate" />
      </template>
    </q-input>

    <q-input outlined v-model="text" :dense="dense" label="Esferico">
      <template v-slot:append>
        <q-icon name="calculate" />
      </template>
    </q-input>

    <div class="row">
      <div class="col">
        <q-select
          outlined
          v-model="model"
          :options="horizontalOptions"
          label="Eixo prisma horizontal"
          :dense="dense"
        />
      </div>
      <div class="col">
        <q-input
          outlined
          v-model="text"
          :dense="dense"
          label="Eixo prisma horizontal valor"
        >
          <template v-slot:append>
            <q-icon name="calculate" />
          </template>
        </q-input>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <q-select
          outlined
          v-model="model"
          :options="verticalOptions"
          label="Eixo prisma vertical"
          :dense="dense"
        />
      </div>
      <div class="col">
        <q-input
          outlined
          v-model="text"
          :dense="dense"
          label="Eixo prisma vertical valor"
        >
          <template v-slot:append>
            <q-icon name="calculate" />
          </template>
        </q-input>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderPrescriptionData',

  data() {
    return {
      text: '',
      ph: '',
      model: null,
      dense: true,
      productOptions: [],
      horizontalOptions: ['NASAL', 'TEMPORAL'],
      verticalOptions: ['UP', 'DOWN']
    }
  }
}
</script>

<style lang="scss" scoped></style>
