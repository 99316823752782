<template>
  <div>
    <div v-if="loading" class="text-center">
      <b-spinner
        style="width: 4rem; height: 4rem"
        label="Large Spinner"
      ></b-spinner>
    </div>
    <b-table
      striped
      hover
      :items="rows"
      :fields="fields"
      v-if="!loading && rows.length > 0"
    >
      <template #cell(name)="data">
        {{ data.item.nome }}
      </template>
    </b-table>
    <b-alert variant="warning" :show="!loading && rows.length === 0">
      Parece que você não tem nenhuma ótica usando o OptiCert
    </b-alert>
  </div>
</template>

<script>
export default {
  name: 'ResourceList',

  watch: {
    url(nv, ov) {
      if (nv !== ov) {
        this.getList()
      }
    }
  },

  data: () => ({
    loading: false,
    rows: [],
    fields: [
      {
        key: 'name',
        label: 'Nome'
      },
      {
        key: 'credits',
        label: 'Créditos'
      },
      {
        key: 'total_orders',
        label: 'Pedidos'
      },
      {
        key: 'total_certificates',
        label: 'Certificados'
      },
      {
        key: 'created_at',
        label: 'Usando desde'
      }
    ]
  }),

  computed: {
    url() {
      return this.$route.fullPath
    }
  },

  methods: {
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },

    getList() {
      this.loading = true
      this.rows = []
      this.$http
        .get(this.url)
        .then((res) => {
          this.rows = res.data.data.map((row) => {
            return {
              id: row.id,
              nome: row.name,
              marca: row.manufacturer ? row.manufacturer.name : '',
              total_orders: row.total_orders,
              total_certificates: row.total_certificates,
              credits: row.credits,
              created_at: row.created_at
            }
          })
        })
        .finally(() => (this.loading = false))
    },

    change(e) {
      this.pagination = e.pagination
      this.getList()
    }
  },

  mounted() {
    this.getList()
  }
}
</script>
