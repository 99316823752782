<template>
  <div :id="id">
    <OptCalculator
      v-if="openCalculator"
      @input-change="setValue"
      @close="goToNext"
      :type="type"
      :indexes="options"
    />
    <q-card
      flat
      bordered
      :class="{ 'my-card': openCalculator }"
      @click="toggle"
      :id="next"
    >
      <q-card-section class="q-pt-none text-center" style="margin-top: 20px">
        <div :class="textClass">{{ value }}</div>
        <div>
          <small>
            {{ small }}
          </small>
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import OptCalculator from './OptCalculator'

export default {
  name: 'OptCalculatorIndex',

  components: { OptCalculator },

  props: {
    type: {
      type: String,
      default: ''
    },

    color: {
      type: String,
      default: 'red'
    },

    show: {
      type: Boolean,
      default: false
    },

    title: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    next: {
      type: String,
      default: ''
    },
    small: {
      type: String
    }
  },

  data: () => ({
    value: 0,
    openCalculator: false,
    options: []
  }),

  computed: {
    textClass() {
      return {
        'text-h2': !this.show,
        'text-h6': this.show,
        'text-red': this.color === 'red',
        'text-green': this.color === 'green',
        'text-blue': this.color === 'blue',
        mb10: !this.show
      }
    }
  },

  watch: {
    openCalculator(nv) {
      this.$emit('card-open', nv)
    }
  },

  methods: {
    getBodyScrollTop() {
      const el = document.scrollingElement || document.documentElement
      return el.scrollTop
    },

    toggle() {
      this.openCalculator = !this.openCalculator
      let elements = document.getElementsByClassName('shadow-5')
      elements.forEach((el) => {
        el.classList.remove('shadow-5')
      })
      let element = document.getElementById(this.id)

      if (this.openCalculator) {
        element.classList.add('shadow-5')
        window.scrollTo(0, element.offsetTop - 220)
      }
    },

    setValue(value) {
      this.value = value.label
    },

    goToNext() {
      //TODO: GET BACK HERE
      if (this.id === 'step3') {
        let next = document.getElementById('next4')
        next.click()
        setTimeout(() => {
          window.scrollTo({
            top: next.offsetTop - 220,
            left: 0,
            behavior: 'smooth'
          })
        }, 250)
      }

      this.openCalculator = false
    },

    chunkArray(array, size) {
      let result = []
      for (let i = 0; i < array.length; i += size) {
        let chunk = array.slice(i, i + size)
        result.push(chunk)
      }
      return result
    },

    getIndexes() {
      this.$axios.get('/indexes').then(async (res) => {
        let data = await res.data.map((item) => ({
          value: item.id,
          label: item.name
        }))

        this.options = this.chunkArray(data, 2)
      })
    }
  },

  mounted() {
    if (this.id === 'type' || this.id === 'indice') {
      this.value = this.title
      this.getIndexes()
    }
  }
}
</script>

<style lang="sass">
.mb10
  margin-bottom: 11px!important
</style>
