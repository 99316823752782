<template>
  <div>
    <div style="margin-top: 20px"><h2>Médico:</h2></div>
    <q-card>
      <q-card-section>
        <input
          class="form-control mt-4 form-control-solid h-auto py-4 px-6 rounded-lg font-size-h6"
          type="text"
          placeholder="Nome"
          name="right_espherical"
          v-model="doctor.name"
          autocomplete="off"
          style="width: 100%; font-size: 1.4em !important; font-weight: bold"
          @keyup="dispatchDoctor"
        />
        <input
          class="form-control mt-4 form-control-solid h-auto py-4 px-6 rounded-lg font-size-h6"
          type="text"
          placeholder="CRM"
          name="right_espherical"
          v-model="doctor.document"
          autocomplete="off"
          style="width: 100%; font-size: 1.4em !important; font-weight: bold"
          @keyup="dispatchDoctor"
        />
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ProductDoctor',

  props: {
    doc: {
      type: Object,
      default: () => ({
        name: null,
        document: null
      })
    }
  },

  computed: {
    ...mapGetters('orders', { order: 'getNewOrder' }),

    doctor: {
      get() {
        return this.doc
      },
      set(doctor) {
        this.dispatchDoctor(doctor)
        this.$emit('input', doctor)
      }
    }
  },

  methods: {
    ...mapActions('orders', ['setDoctor']),

    dispatchDoctor() {
      this.setDoctor(JSON.parse(JSON.stringify(this.doctor)))
    }
  }
}
</script>

<style lang="scss" scoped></style>
