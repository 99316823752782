import original from './state'

export function SET_TYPE(state) {
  state.type = true
}
export function SET_TOTAL(state, payload) {
  state.loading = payload
}

export function RESET_STATE(state) {
  // console.log(state, original)
  Object.assign(state, original)
}

export function SET_LOADER(state, payload) {
  state.loading = payload
}

export function SET_ORDERS(state, payload) {
  state.orders = payload.data
  state.total = payload.total
}

export function SET_ERRORS(state, payload) {
  state.errors = payload
}

export function PUSH_ORDER(state, payload) {
  state.orders.push(payload)
}

export function SET_PRISM(state, payload) {
  state.hasPrism = payload
}

export function SET_MARK(state, payload) {
  state.hasMark = payload
}

export function SET_ORDER(state, payload) {
  state.order = payload
}

export function SET_NEW_ORDER(state, payload) {
  state.newOrder = payload
}

export function REMOVE_ORDER(state, id) {
  state.orders.forEach((item, k) => {
    if (item.id === id) {
      state.orders.splice(k, 1)
    }
  })
}

export function SET_CUSTOMER_DATA(state, payload) {
  state.newOrder.customer = payload
}

export function UPDATE_TYPE(state, payload) {
  state.newOrder.type_id = payload
}

export function UPDATE_SEND_TO(state, payload) {
  state.newOrder.send_to = payload
}

export function UPDATE_DOCTOR(state, payload) {
  state.newOrder.doctor = payload
}

export function UPDATE_FRAME(state, payload) {
  state.newOrder.frame = payload
}

export function UPDATE_INDEX(state, payload) {
  state.newOrder.index_id = payload
}

export function UPDATE_TECHNOLOGY(state, payload) {
  state.newOrder.technology_id = payload
}

export function UPDATE_COLOR(state, payload) {
  state.newOrder.color_id = payload
}

export function UPDATE_MANUFACTURER(state, payload) {
  state.newOrder.manufacturer_id = payload
}

export function UPDATE_TREATMENT(state, payload) {
  state.newOrder.treatment_id = payload
}

export function UPDATE_PRODUCT(state, payload) {
  state.newOrder.product_id = payload.value
  state.hasMark = payload.category.has_mark
}

export function UPDATE_HOOP(state, payload) {
  state.newOrder.hoop_id = payload
}

export function UPDATE_LENS(state, payload) {
  state.newOrder.lens_id = payload
}

export function UPDATE_VBOX(state, payload) {
  state.newOrder.frame.vBox = payload
}

export function UPDATE_HBOX(state, payload) {
  state.newOrder.frame.hBox = payload
}

export function UPDATE_DISTANCEBETWEENLENSES(state, payload) {
  state.newOrder.frame.distanceBetweenLenses = payload
}

export function UPDATE_LARGESTDIAMETER(state, payload) {
  state.newOrder.frame.largestDiameter = payload
}

export function UPDATE_ASSEMPLY_DIAMETER(state, payload) {
  state.newOrder.frame.assemblyDiameter = payload
}

export function UPDATE_DOMINANT_EYE(state, payload) {
  state.newOrder.individualParameters.dominantEye = payload
}

export function UPDATE_VMAP(state, payload) {
  state.newOrder.individualParameters.vmap = payload
}

export function UPDATE_REMARK(state, payload) {
  state.newOrder.remark = payload
}

//RIGHT
export function UPDATE_ADDITION_RIGHT_EYE(state, payload) {
  state.newOrder.prescription.right_eye.addition = payload
}

export function UPDATE_AXIS_RIGHT_EYE(state, payload) {
  state.newOrder.prescription.right_eye.axis = payload
}

export function UPDATE_CYLINDRICAL_RIGHT_EYE(state, payload) {
  state.newOrder.prescription.right_eye.cylindrical = payload
}

export function UPDATE_FITTING_HEIGHT_RIGHT_EYE(state, payload) {
  state.newOrder.prescription.right_eye.fittingHeight = payload
}

export function UPDATE_HORIZONTAL_PRISM_AXIS_RIGHT_EYE(state, payload) {
  state.newOrder.prescription.right_eye.horizontalPrismAxis = payload
}

export function UPDATE_HORIZONTAL_PRISM_AXIS_VALUE_RIGHT_EYE(state, payload) {
  state.newOrder.prescription.right_eye.horizontalPrismValue = payload
}

export function UPDATE_VERTICAL_PRISM_AXIS_RIGHT_EYE(state, payload) {
  state.newOrder.prescription.right_eye.verticalPrismAxis = payload
}

export function UPDATE_VERTICAL_PRISM_AXIS_VALUE_RIGHT_EYE(state, payload) {
  state.newOrder.prescription.right_eye.verticalPrismValue = payload
}

export function UPDATE_INTER_PUPILAR_DISTANCE_RIGHT_EYE(state, payload) {
  state.newOrder.prescription.right_eye.interPupillaryDistance = payload
}

export function UPDATE_SPHERICAL_RIGHT_EYE(state, payload) {
  state.newOrder.prescription.right_eye.spherical = payload
}

export function UPDATE_CURVATURE_RIGHT_EYE(state, payload) {
  state.newOrder.prescription.right_eye.curvature = payload
}
export function UPDATE_PANTOGRAPHIC_RIGHT_EYE(state, payload) {
  state.newOrder.prescription.right_eye.pantographic = payload
}
export function UPDATE_EYCODE_RIGHT_EYE(state, payload) {
  state.newOrder.prescription.right_eye.eyecode = payload
}
export function UPDATE_CVP_RIGHT_EYE(state, payload) {
  state.newOrder.prescription.right_eye.cvp = payload
}

//LEFT
export function UPDATE_ADDITION_LEFT_EYE(state, payload) {
  state.newOrder.prescription.left_eye.addition = payload
}

export function UPDATE_AXIS_LEFT_EYE(state, payload) {
  state.newOrder.prescription.left_eye.axis = payload
}

export function UPDATE_CYLINDRICAL_LEFT_EYE(state, payload) {
  state.newOrder.prescription.left_eye.cylindrical = payload
}

export function UPDATE_FITTING_HEIGHT_LEFT_EYE(state, payload) {
  state.newOrder.prescription.left_eye.fittingHeight = payload
}

export function UPDATE_HORIZONTAL_PRISM_AXIS_LEFT_EYE(state, payload) {
  state.newOrder.prescription.left_eye.horizontalPrismAxis = payload
}

export function UPDATE_HORIZONTAL_PRISM_AXIS_VALUE_LEFT_EYE(state, payload) {
  state.newOrder.prescription.left_eye.horizontalPrismValue = payload
}

export function UPDATE_VERTICAL_PRISM_AXIS_LEFT_EYE(state, payload) {
  state.newOrder.prescription.left_eye.verticalPrismAxis = payload
}

export function UPDATE_VERTICAL_PRISM_AXIS_VALUE_LEFT_EYE(state, payload) {
  state.newOrder.prescription.left_eye.verticalPrismValue = payload
}

export function UPDATE_INTER_PUPILAR_DISTANCE_LEFT_EYE(state, payload) {
  state.newOrder.prescription.left_eye.interPupillaryDistance = payload
}

export function UPDATE_SPHERICAL_LEFT_EYE(state, payload) {
  state.newOrder.prescription.left_eye.spherical = payload
}

export function UPDATE_CURVATURE_LEFT_EYE(state, payload) {
  state.newOrder.prescription.left_eye.curvature = payload
}
export function UPDATE_PANTOGRAPHIC_LEFT_EYE(state, payload) {
  state.newOrder.prescription.left_eye.pantographic = payload
}
export function UPDATE_EYECODE_LEFT_EYE(state, payload) {
  state.newOrder.prescription.left_eye.eyecode = payload
}
export function UPDATE_CVP_LEFT_EYE(state, payload) {
  state.newOrder.prescription.left_eye.cvp = payload
}
export function UPDATE_SPHERICAL_NEAR_LEFT_EYE(state, payload) {
  state.newOrder.prescription.left_eye.sphericalNear = payload
}
