<template>
  <div>
    <q-card
      flat
      bordered
      v-for="(technology, key) in technologies.flat()"
      :key="key"
      @click.prevent.native="setValue(technology)"
    >
      <q-card-section class="self-center q-mt-md">
        <div class="text-h5">{{ technology.label.toUpperCase() }}</div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: 'ProductTechnology',

  computed: {
    ...mapGetters('menus', {
      technologies: 'getTechnologies',
      loader: 'getLoader'
    }),
    ...mapGetters('orders', {
      order: 'getNewOrder'
    })
  },

  methods: {
    ...mapActions('menus', [
      'addPanel',
      'setColors',
      'fetchProducts',
      'fetchIndex'
    ]),
    ...mapActions('orders', ['setTechnology', 'setColor']),

    async setValue(val) {
      await this.setTechnology(val.value)
      if (val.colors.length === 0) {
        let loader = this.$loading.show()
        this.setColor(0)
        this.$http
          .query('/lists/indexes', {
            params: {
              technology_id: this.order.technology_id,
              type_id: this.order.type_id,
              manufacturer_id: this.order.manufacturer_id
            }
          })
          .then(({ data }) => {
            this.$store.commit('menus/SET_INDEX', _.chunk(data, 2))
          })
          .finally(() => loader.hide())
      } else {
        let loader = this.$loading.show()
        this.$http
          .query('lists/colors', {
            params: { technology: val.value }
          })
          .then(async ({ data }) => {
            const colors = await data[0].colors.map((item) => ({
              value: item.id,
              label: item.name,
              indexes: item.indexes.map((item) => ({
                label: item.name,
                value: item.id
              }))
            }))

            this.$store.commit('menus/SET_COLOR', _.chunk(colors, 2))
            // this.$store
            // .commit("menus/SET_COLORS", _.chunk(data.colors, 2))
          })
          .finally(() => loader.hide())
      }

      this.addPanel({
        ...val,
        action: 'removeTechnology'
      })
    }
  }
}
</script>
