<template>
  <b-card style="margin-top: 15px">
    <q-card-section>
      <div class="text-h5" style="padding: 0">
        <small style="color: #909090">total:</small> <br />
        {{ total }}
      </div>
    </q-card-section>
  </b-card>
</template>

<script>
export default {
  name: 'ProductTotal',

  props: {
    total: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped></style>
