import Vue from 'vue'

const requireComp = require.context('../../components', true, /[\w-].vue$/)
requireComp.keys().forEach((fileName) => {
  const componentConfig = requireComp(fileName)
  const componentName = fileName.replace('./', '').replace('.vue', '')
  let name = null
  const parts = componentName.split('/')
  if (parts.length > 1) {
    name = parts.slice(-1).pop()
  } else {
    name = parts.shift()
  }
  if (name && name !== 'Component') {
    Vue.component(name, componentConfig.default)
  }
})
