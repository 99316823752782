<template>
  <div class="mt-10">
    <h3 class="card-label font-weight-bolder text-dark">Galeria de imagens</h3>
    <span class="text-muted font-weight-bold font-size-sm mt-1"
      >Faça upload das imagens de seus certificados e/ou promoções. Acesse
      https://opticonecta.com.br/upload-de-imagens e veja as especificações das
      imagens e instruções para configuração e uso.</span
    >
    <div class="row mb-10">
      <div class="col">
        <vue-dropzone
          ref="myVueDropzone"
          id="dropzone"
          :options="dropzoneOptions"
          :useCustomSlot="true"
          @vdropzone-success="setImage"
        >
          <div class="dropzone-custom-content">
            <h3 class="dropzone-custom-title">
              Arraste e solte sua imagem aqui!
            </h3>
            <div class="subtitle">
              ...ou clique para selecionar uma imagem do seu computador.
            </div>
          </div>
        </vue-dropzone>
        <small>Imagens com no máximo 5mb de tamanho e 1024px x 768px</small>
      </div>
    </div>
    <div
      class="card-title align-items-start flex-column"
      v-if="list.length > 0"
    ></div>
    <div v-for="(image, idx) in list" :key="idx">
      <b-card img-alt="Image" img-top class="mb-4">
        <div style="display: flex">
          <div>
            <img :src="image.image" style="width: 120px" />
          </div>
          <div
            style="
              flex: 4;
              flex-direction: row-reverse;
              display: flex;
              align-items: center;
            "
          >
            <button
              class="btn btn-danger"
              v-b-modal.modal-1
              @click="setImageId(image.id)"
            >
              X
            </button>
          </div>
        </div>
      </b-card>
    </div>
    <b-modal
      id="modal-1"
      title="Deseja remover está imagem?"
      @ok="deleteImage(image)"
    >
      <p class="my-4">Esta ação não poderá ser desfeita.</p>
    </b-modal>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import paths from '../../core/services/paths.service'

export default {
  name: 'ImageList',

  components: {
    vueDropzone: vue2Dropzone
  },

  data: () => ({
    list: [],
    imageId: null,
    dropzoneOptions: {
      url: `${paths.API}/images`,
      thumbnailWidth: 150,
      maxFilesize: 10,
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${localStorage.getItem('id_token')}`
      }
    }
  }),

  computed: {
    images() {
      return this.$store.state.auth.store.images.filter(
        (item) => item.store_id !== null
      )
    }
  },

  methods: {
    setImage(val) {
      const data = JSON.parse(localStorage.getItem('data'))
      const images = JSON.parse(val.xhr.response)
      data.store.images = images
      localStorage.setItem('data', JSON.stringify(data))
      this.list = images
    },

    setImageId(image) {
      this.imageId = image
    },

    deleteImage() {
      this.$http.delete(`/images/${this.imageId}`).then(({ data }) => {
        const items = JSON.parse(localStorage.getItem('data'))
        const images = data
        items.store.images = images
        localStorage.setItem('data', JSON.stringify(items))
        this.list = data
      })
    }
  },

  mounted() {
    if (this.images.length > 0) {
      this.list = JSON.parse(JSON.stringify(this.images))
    }
  }
}
</script>

<style lang="scss" scoped>
.vue-dropzone {
  background: #f5f5f5;
  box-shadow: 1px 1px 5px #cccccc73 inset;
  border: 1px solid #f5f5f5;
}
.dropzone-custom-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.dropzone-custom-title {
  margin-top: 0;
  color: #000;
  font-size: 12px !important;
}
</style>
