<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header align-items-center border-0 mt-4">
      <h3 class="card-title align-items-start flex-column">
        <span class="font-weight-bolder text-dark"> Atividade </span>
      </h3>
      <div class="card-toolbar"></div>
    </div>
    <div class="card-body pt-4">
      <div class="timeline timeline-6 mt-3">
        <template v-for="(item, i) in items">
          <div class="timeline-item align-items-start" :key="i">
            <div
              class="timeline-label font-weight-bolder text-dark-75 font-size-lg"
            >
              {{ item.time }}
            </div>
            <div class="timeline-badge">
              <i class="icon-xl" :class="item.badge"></i>
            </div>
            <div
              class="font-weight-mormal font-size-lg timeline-content text-muted pl-3"
            >
              <span
                :class="{
                  'font-weight-bolder text-dark-75': item.bold,
                  'mr-4': item.images
                }"
                v-html="item.desc"
              ></span>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderActivity',

  props: {
    routes: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    lastElement(i) {
      if (i === this.list.length - 1) {
        return false
      } else {
        return true
      }
    },

    formatDate(date) {
      let dateParts = date.split('T')
      let data = dateParts[0].split('-')

      return data[2] + '/' + data[1]
    }
  },

  computed: {
    items() {
      let routes = JSON.parse(JSON.stringify(this.routes))
      return routes.reverse().map((item, idx) => {
        return {
          time: item.time + '\n' + this.formatDate(item.date),
          desc: item.location.description,
          badge: `fa fa-genderless ${
            idx === 0
              ? 'text-success'
              : this.routes.length == idx + 1
              ? 'text-info'
              : 'text-default'
          }`,
          bold: true
        }
      })
    }
  }
}
</script>
