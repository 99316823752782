export function GET_CERTIFICATES(state) {
  return state.certificates.certificates
}

export function GET_CERTIFICATES_TOTAL(state) {
  return state.total
}

export function GET_LOADER(state) {
  return state.loading
}
