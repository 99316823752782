<template>
  <div style="width: 100%">
    <div class="row" v-if="user.is_store || user.is_salesman">
      <div v-if="store">
        <img :src="store.logo" style="width: 200px" />
      </div>
    </div>
    <div
      class="row"
      v-if="user.is_store || user.is_salesman"
      style="width: 100%"
    >
      <div>
        <DashboardNumbers />
      </div>
    </div>
    <div v-if="user.is_lab || user.is_admin" style="width: 100%">
      <DashboardTable />
    </div>
  </div>
</template>

<script>
import DashboardNumbers from '@/components/DashboardNumbers.vue'
import DashboardTable from '@/components/DashboardTable.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Dashboard',
  components: {
    DashboardNumbers,
    DashboardTable
  },
  computed: {
    ...mapGetters(['store']),

    user() {
      return this.$store.state.auth.user
    }
  }
}
</script>
