<template>
  <div>
    <q-tabs
      v-model="tab"
      dense
      class="text-grey"
      active-color="primary"
      indicator-color="primary"
      align="justify"
      narrow-indicator
    >
      <q-tab name="re" label="Direito" icon="visibility" />
      <q-tab name="le" label="Esquerdo" icon="visibility" />
    </q-tabs>

    <q-separator />

    <q-tab-panels v-model="tab" animated>
      <q-tab-panel name="re">
        <div class="text-h6">Olho direito</div>
        <OrderPrescriptionData />
      </q-tab-panel>

      <q-tab-panel name="le">
        <div class="text-h6">Olho esquerdo</div>
        <OrderPrescriptionData />
      </q-tab-panel>
    </q-tab-panels>
  </div>
</template>

<script>
import OrderPrescriptionData from './OrderPrescriptionData'

export default {
  name: 'OrderPrescription',

  components: { OrderPrescriptionData },

  data() {
    return {
      text: '',
      ph: '',
      tab: 're',
      dense: true
    }
  }
}
</script>

<style lang="scss" scoped></style>
