<template>
  <a class="lens" @click="setLensModel(lens.id)">
    <span :class="classe">{{ lens.name }}</span>
    <img :src="lens.image" alt="" />
  </a>
</template>

<script>
export default {
  name: 'Lens',

  props: {
    lens: {
      type: Object
    }
  },

  data: () => ({
    classe: {
      selectedSpan: true,
      selected: false
    }
  }),

  methods: {
    setLensModel(val) {
      this.$store.dispatch('orders/setLens', val)
      let elements = document.getElementsByClassName('selectedSpan')
      elements.forEach((el) => {
        el.classList.remove('selected')
      })
      this.classe.selected = !this.classe.selected
    }
  },

  mounted() {
    const { lens_id } = this.$store.state.orders.newOrder
    if (lens_id === this.lens.lens_id) {
      this.setLensModel(lens_id)
    }
  }
}
</script>

<style lang="sass" scoped>
.lens
  width: 200px
  margin-right: 5px
  border-radius: 7px
.selected
  background: #00000091
  color: #ffffff
span
  position: absolute
  text-align: center
  margin-right: 40px
  background: #00000017
  color: #2d2d2d
  padding: 2px 5px
  border-radius: 5px
  margin-top: 18px
img
  max-width: 200px!important
</style>
