<template>
  <q-card class="bg-grey-9 my-card" @click="$router.push('/certificados')">
    <q-card-section>
      <div class="text-subtitle2 text-center" style="color: #494d61">
        CERTIFICADOS
      </div>
      <div class="text-h3 text-center" style="color: #494d61">{{ total }}</div>
    </q-card-section>
  </q-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'TotalCertificates',

  computed: {
    ...mapGetters('certificates', {
      certificates: 'GET_CERTIFICATES',
      total: 'GET_CERTIFICATES_TOTAL'
    })
  },

  methods: {
    ...mapActions('certificates', ['fetchCertificates'])
  },

  mounted() {
    this.fetchCertificates()
  }
}
</script>
