<template>
  <q-card v-if="panels.length > 0" style="background: #dce1e5 !important">
    <q-card-section class="self-center q-mt-md self-center">
      <div
        class="text-h5"
        v-for="(panel, key) in panelsList"
        :key="key"
        style="font-size: 1.4em"
      >
        <div v-html="panel.label.toUpperCase()"></div>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ProductPanel',

  computed: {
    ...mapGetters({ panels: 'menus/getPanels' }),

    hasDiopter() {
      return !!this.panels.filter((item) => item.has_diopter).length
    },

    panelsList() {
      if (!this.hasDiopter) {
        return this.panels.filter((panel) => panel.action === 'removeType')
      }
      return this.panels
    }
  },

  methods: {
    ...mapActions('menus', { remove: 'removePanel' })
  }
}
</script>
