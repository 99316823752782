<template>
  <div :id="id">
    <OptCalculator
      v-if="openCalculator"
      @input-change="setValue"
      @close="goToNext"
      :type="type"
      :types="options"
    />
    <q-card
      flat
      bordered
      :class="{ 'my-card': openCalculator }"
      @click="toggle"
      :id="next"
    >
      <q-card-section class="q-pt-none text-center" style="margin-top: 20px">
        <div :class="textClass">{{ value }}</div>
        <div>
          <small>
            {{ small }}
          </small>
        </div>
      </q-card-section>
    </q-card>

    <q-card
      flat
      bordered
      :disabled="loading"
      :class="{ 'my-card': openCalculator }"
      v-if="showProductsList"
      style="margin-top: 10px"
    >
      <q-card-section class="q-pt-none text-center" style="margin-top: 20px">
        <div>
          <q-select
            borderless
            :loding="loading"
            v-model="selected"
            :options="products"
            label="Produto Escolhido"
          />
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import OptCalculator from './OptCalculator'

export default {
  name: 'OptCalculatortype',

  components: { OptCalculator },

  props: {
    type: {
      type: String,
      default: ''
    },

    color: {
      type: String,
      default: 'red'
    },

    show: {
      type: Boolean,
      default: false
    },

    title: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    next: {
      type: String,
      default: ''
    },
    small: {
      type: String
    }
  },

  data: () => ({
    value: 0,
    type_id: 0,
    loading: false,
    openCalculator: false,
    showProductsList: false,
    options: [],
    selected: null,
    products: []
  }),

  computed: {
    textClass() {
      return {
        'text-h2': !this.show,
        'text-h6': this.show,
        'text-red': this.color === 'red',
        'text-green': this.color === 'green',
        'text-blue': this.color === 'blue',
        mb10: !this.show
      }
    }
  },

  watch: {
    openCalculator(nv) {
      this.$emit('card-open', nv)
    }
  },

  methods: {
    toggle() {
      this.openCalculator = !this.openCalculator
      let elements = document.getElementsByClassName('shadow-5')
      elements.forEach((el) => {
        el.classList.remove('shadow-5')
      })
      let element = document.getElementById(this.id)

      if (this.openCalculator) {
        element.classList.add('shadow-5')
        window.scrollTo(0, element.offsetTop - 220)
      }
    },

    setValue(obj) {
      this.value = obj.label
      this.type_id = obj.value
      this.getProducts()
    },

    getProducts() {
      this.showProductsList = true
      this.loading = true
      this.$axios
        .get(`/products/?type_id=${this.type_id}`)
        .then(async (res) => {
          this.products = await res.data.map((item) => ({
            value: item.id,
            label: `${item.manufacturer.name} - ${
              item.name
            } <br> ${this.currency(item.price)}`
          }))
        })
        .finally(() => (this.loading = false))
    },

    currency(value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return `R$ ${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`
    },

    goToNext() {
      let el = document.getElementById('indice')
      let next = document.getElementById('ind')
      next.click()
      setTimeout(() => {
        window.scrollTo({
          top: el.offsetTop - 220,
          left: 0,
          behavior: 'smooth'
        })
      }, 250)

      this.openCalculator = false
    },

    chunkArray(array, size) {
      let result = []
      for (let i = 0; i < array.length; i += size) {
        let chunk = array.slice(i, i + size)
        result.push(chunk)
      }
      return result
    },

    getTypes() {
      this.$axios.get('/types').then(async (res) => {
        let data = await res.data.map((item) => ({
          value: item.id,
          label: item.name
        }))

        this.options = this.chunkArray(data, 2)
      })
    }
  },

  created() {
    this.getTypes()
  },

  mounted() {
    if (this.id === 'type' || this.id === 'indice') {
      this.value = this.title
    }
  }
}
</script>

<style lang="sass">
.mb10
  margin-bottom: 11px!important
</style>
