import ApiService from '../api.service'

//actions types
export const FETCH_SALESMEN = 'fetchSalesmen'
export const SAVE_SALESMEN = 'saveSalesmen'
export const INVITE_SALESMAN = 'inviteSalesmen'

// mutation types
export const PUSH_SALESMAN = 'pushSalesman'
export const SET_SALESMEN = 'setSalesmen'
export const SET_ERROR = 'setError'

const getters = {
  getSalesmen(state) {
    return state.salesmen
  }
}

const actions = {
  [FETCH_SALESMEN](context, data) {
    return new Promise((resolve) => {
      ApiService.get('salesman', data)
        .then(({ data }) => {
          context.commit(SET_SALESMEN, data.data)
          resolve(data)
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors)
        })
    })
  },

  [SAVE_SALESMEN](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post('salesman', credentials)
        .then(({ data }) => {
          context.commit(PUSH_SALESMAN, data)
          resolve(data)
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors)
        })
    })
  },

  [INVITE_SALESMAN](context, payload) {
    return new Promise((resolve) => {
      ApiService.post('salesman/invite', payload)
        .then(({ data }) => {
          data.data.map((salesman) => context.commit(PUSH_SALESMAN, salesman))
          resolve(data)
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors)
        })
    })
  },

  [SET_ERROR](state, error) {
    state.errors = error
  }
}

const mutations = {
  [PUSH_SALESMAN](state, payload) {
    state.salesmen.unshift(payload)
  },
  [SET_SALESMEN](state, payload) {
    state.salesmen = payload
  }
}

const state = {
  salesmen: [],
  errors: null
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
