<template>
  <div>
    <div class="q-pa-md q-gutter-md" style="min-height: 5000px">
      <div class="text-h5">{{ $route.meta.title }}aaa</div>
      <q-card flat bordered>
        <q-card-section>
          <div class="text-h7"></div>
        </q-card-section>

        <q-card-section class="q-pt-none text-center"> dasdas </q-card-section>
      </q-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderFrame'
}
</script>

<style lang="scss" scoped></style>
