import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth.module'
import breadcrumbs from './breadcrumbs.module'
import config from './config.module'
import htmlClass from './htmlclass.module'
import orders from './orders/index'
import salesmen from './salesman.module'
import certificates from './certificates/index'
import menus from './menus/index'
import profile from './profile.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    profile,
    orders,
    certificates,
    salesmen,
    menus
  }
})
