<template>
  <div>
    <q-card flat bordered>
      <q-card-section class="self-center q-mt-md">
        <div class="text-h5">
          <b-form-select
            v-model="selected"
            :options="options"
            @change="setValue"
          ></b-form-select>
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ProductList',

  data: () => ({
    title: 'ESCOLHA O PRODUTO',
    id: 'type_id',
    selected: 'escolha'
  }),

  computed: {
    ...mapGetters('menus', {
      show: 'hasProducts',
      products: 'getProducts',
      loading: 'getLoader'
    }),

    ...mapGetters('orders', {
      order: 'getNewOrder'
    }),

    options() {
      let result = [
        {
          text: 'Escolha o produto',
          value: 'escolha'
        }
      ]

      this.products.map((item) =>
        result.push({
          value: item.value,
          text: item.label.replace('<br>', ' - ')
        })
      )

      return result
    }
  },

  methods: {
    ...mapActions('orders', ['setProduct']),
    ...mapActions('menus', ['fetchHoop', 'addPanel', 'removePanel']),

    setValue(item) {
      let val = this.products.filter((product) => product.value === item)[0]
      this.removePanel({
        ...val,
        action: 'removeProduct'
      })

      this.addPanel({
        ...val,
        action: 'removeProduct'
      })

      this.setProduct(val)
      this.fetchHoop(val.value)
    }
  }
}
</script>

<style lang="sass" scoped>
.orverlay
  position: absolute
  background: #00000094
  width: 100%
  height: 100%
  z-index: 3!important
  top: 0
  right: 0
</style>
