<template>
  <div :id="id">
    <OptCalculator
      v-if="openCalculator"
      @input-change="setValue"
      @close="goToNext"
    />
    <q-card
      :class="{ 'my-card': openCalculator }"
      @click.prevent.native="toggle"
      :id="next"
      v-show="showCard"
    >
      <q-card-section class="q-pb-sm">
        <div class="">{{ title }}</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        <div :class="textClass">{{ value }}</div>
      </q-card-section>
    </q-card>
    <div
      v-if="
        id === 'horizontalPrismAxisLeft' || id === 'horizontalPrismAxisRight'
      "
      class="radioGroup"
    >
      <b-form-group v-slot="{ ariaDescribedby }">
        <b-form-radio-group
          id="btn-radios-2"
          v-model="horizontalAxis"
          :options="[
            {
              text: 'NASAL',
              value: 'NASAL'
            },
            {
              text: 'TEMPORAL',
              value: 'TEMPORAL'
            }
          ]"
          :aria-describedby="ariaDescribedby"
          button-variant="outline-primary"
          size="sm"
          name="radio-btn-outline"
          buttons
        ></b-form-radio-group>
      </b-form-group>
    </div>
    <div
      v-if="id === 'verticalPrismAxisLeft' || id === 'verticalPrismAxisRight'"
      class="radioGroup"
    >
      <b-form-group v-slot="{ ariaDescribedby }">
        <b-form-radio-group
          id="btn-radios-2"
          v-model="verticalAxis"
          :options="[
            {
              text: 'SUPERIOR',
              value: 'UP'
            },
            {
              text: 'INFERIOR',
              value: 'DOWN'
            }
          ]"
          :aria-describedby="ariaDescribedby"
          button-variant="outline-primary"
          size="sm"
          name="radio-btn-outline"
          buttons
        ></b-form-radio-group>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import OptCalculator from './OptCalculator'
import { scroller } from 'vue-scrollto/src/scrollTo'
import { mapGetters } from 'vuex'

const scroll = scroller()

export default {
  name: 'OptCalculatorCard',

  components: { OptCalculator },

  props: {
    action: {
      type: String,
      default: ''
    },

    color: {
      type: String,
      default: 'red'
    },

    eye: {
      type: String,
      default: 'right_eye'
    },

    title: {
      type: String,
      required: true
    },

    id: {
      type: String,
      required: true
    },

    next: {
      type: String,
      default: ''
    },

    range: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    openCalculator: false,
    horizontalAxis: 'NASAL',
    verticalAxis: 'UP'
  }),

  computed: {
    ...mapGetters('orders', { prism: 'GET_PRISM', order: 'getNewOrder' }),
    ...mapGetters('menus', { products: 'getProducts' }),

    isVarilux() {
      const { product_id } = this.order

      if (product_id) {
        const product = this.products.filter(
          (item) => item.value === product_id
        )[0]
        return product.varilux
      }

      return false
    },

    textClass() {
      return {
        'text-h5': true,
        'text-deep-orange-14': this.color === 'red' ? true : false,
        'text-indigo-14': this.color === 'blue' ? true : false
      }
    },

    position() {
      if (this.eye === 'Right') {
        return 'RightEye'
      }
      return 'LeftEye'
    },

    small() {
      if (this.range) {
        return `de ${this.range[0]} até ${this.range[1]}`
      }

      return ''
    },

    prescription() {
      return this.order.prescription[`${this.eye.toLowerCase()}_eye`]
    },

    order() {
      return this.$store.getters['orders/getNewOrder']
    },

    value() {
      let { prescription, id, eye } = this
      if (id === `horizontalPrismAxis${eye}`) {
        return prescription['horizontalPrismValue']
      }

      if (id === `verticalPrismAxis${eye}`) {
        return prescription['verticalPrismValue']
      }

      if (id === 'esphericalNearRight') {
        return prescription.addition + prescription.spherical
      }

      if (id === 'esphericalNearLeft') {
        return prescription.addition + prescription.spherical
      }

      return prescription[id.replace(eye, '')]
    },

    showCard() {
      if (this.id === 'horizontalPrismAxisRight' && !this.prism) return false
      if (this.id === 'verticalPrismAxisRight' && !this.prism) return false
      if (this.id === 'horizontalPrismAxisLeft' && !this.prism) return false
      if (this.id === 'verticalPrismAxisLeft' && !this.prism) return false

      if (this.id === 'curvatureRight' && !this.isVarilux) return false
      if (this.id === 'pantographicRight' && !this.isVarilux) return false
      if (this.id === 'eyecodeRight' && !this.isVarilux) return false
      if (this.id === 'cvpRight' && !this.isVarilux) return false

      if (this.id === 'curvatureLeft' && !this.isVarilux) return false
      if (this.id === 'pantographicLeft' && !this.isVarilux) return false
      if (this.id === 'eyecodeLeft' && !this.isVarilux) return false
      if (this.id === 'cvpLeft' && !this.isVarilux) return false

      return true
    }
  },

  watch: {
    openCalculator(nv) {
      this.$emit('card-open', nv)
    },

    horizontalAxis(val) {
      this.$store.dispatch(`orders/setHorizontalPrismAxis${this.position}`, val)
    },

    verticalAxis(val) {
      this.$store.dispatch(`orders/setVerticalPrismAxis${this.position}`, val)
    }
  },

  methods: {
    toggle() {
      this.openCalculator = !this.openCalculator
      let elements = document.getElementsByClassName('shadow-5')

      elements.forEach((el) => {
        el.classList.remove('shadow-5')
      })

      let element = document.getElementById(this.id)

      scroll(element, {
        container: 'body',
        duration: 500,
        easing: 'ease',
        offset: -60,
        force: true,
        cancelable: true,
        y: true
      })

      if (this.openCalculator) {
        element.classList.add('shadow-5')
      }
    },

    inRange(value) {
      if (this.id === `interPupillaryDistance${this.eye}`) {
        return true
      }

      if (this.range && this.id !== `fittingHeight${this.eye}`) {
        let number = parseFloat(value)
        return number >= this.range[0] && number <= this.range[1]
      }

      return true
    },

    setValue(value) {
      if (!this.inRange(value)) {
        this.$bvToast.toast(`Somente valores ${this.small}`, {
          title: 'Atenção',
          autoHideDelay: 5000,
          appendToast: true
        })

        this.$store.dispatch(this.action, 0)
        let back = document.getElementById('backbutton')
        ;[1, 2, 3, 4, 5].forEach(() => back.click())

        return true
      }

      if (this.id === 'horizontalPrismAxis') {
        this.$store.dispatch(
          `orders/setHorizontalPrismAxisValue${this.position}`,
          value
        )
      } else if (this.id === 'verticalPrismAxis') {
        this.$store.dispatch(
          `orders/setVerticalPrismAxisValue${this.position}`,
          value
        )
      } else {
        this.$store.dispatch(this.action, value)
      }
    },

    goToNext() {
      let el = document.getElementById(this.id)
      if (this.id === 'verticalPrismAxisRight') {
        const el = document.getElementById('sphericalLeft')
        let next = el.childNodes[1]
        next.click()
        this.openCalculator = false
      } else if (this.id === 'verticalPrismAxisLeft') {
        document
          .getElementById('verticalPrismAxisLeft')
          .classList.remove('shadow-5')
        this.openCalculator = false
      } else if (this.id === 'fittingHeightRight') {
        document
          .getElementById('fittingHeightRight')
          .classList.remove('shadow-5')
        let sl = document.getElementById('sphericalLeft')
        sl.click()
        this.openCalculator = false
        var aa = document.getElementById('sphericalLeft')
        aa.children[0].click()
      } else if (this.id === 'fittingHeightLeft') {
        document
          .getElementById('fittingHeightRight')
          .classList.remove('shadow-5')
        let sl = document.getElementById('sphericalLeft')
        sl.click()
        this.openCalculator = false
      } else {
        let next = el.nextSibling.childNodes[1]
        next.click()
        this.openCalculator = false
      }
    }
  }
}
</script>

<style lang="sass">
.mb10
  margin-bottom: 11px!important
.my-card
  z-index: 1!important
.text-h6
  margin-top: -14px!important
.radioGroup
  background: #fff
  padding-left: 15px
  padding-bottom: 15px
  border-bottom-left-radius: 5px
  border-bottom-right-radius: 5px
  height: 58px
  padding-top: 14px
  margin-top: -31px
</style>
