<template>
  <div>
    <div class="card-header pt-10 pl-0 pb-0 mt-20 mb-10" v-if="user.is_lab">
      <div
        class="card-title align-items-start flex-column"
        style="margin-left: 0px"
      >
        <h3 class="card-label font-weight-bolder text-dark">
          Configurações do Laboratório
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Modifique cores e logo da sua ótica</span
        >
      </div>
    </div>
    <div class="form-group row" v-if="user.is_lab">
      <label class="col-xl-3 col-lg-3 col-form-label"
        >Valor por certificado</label
      >
      <small></small>
      <div class="col-lg-9 col-xl-6">
        <div style="padding: 10px 0px">
          R$ {{ laboratory.certificate_cost }}
        </div>
        <small>Quanto você nos paga por certificado emitido.</small>
      </div>
    </div>
    <div class="form-group row" v-if="user.is_lab">
      <label class="col-xl-3 col-lg-3 col-form-label"
        >Valor por certificado impresso</label
      >
      <small></small>
      <div class="col-lg-9 col-xl-6" v-if="user.is_lab">
        <div class="input-group input-group-lg input-group-solid">
          <input
            type="number"
            class="form-control form-control-lg form-control-solid"
            ref="printed_certificate_cost"
            v-model="laboratory.printed_certificate_cost"
          />
        </div>
        <small
          >Custo do certificado impresso para relatórios mais precisos.</small
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LaboratoryProfile',

  data: () => ({
    laboratory: {
      printed_certificate_cost: 0,
      certificate_cost: 0
    }
  }),

  computed: {
    user: {
      get() {
        return this.$store.state.auth.user
      },
      set(user) {
        this.$store.dispatch('auth/setUser', user)
      }
    },

    lab() {
      return this.user.laboratory
    }
  },

  mounted() {
    if (this.user.is_lab) {
      this.laboratory.printed_certificate_cost = JSON.parse(
        JSON.stringify(this.lab.printed_certificate_cost)
      )
      this.laboratory.certificate_cost = JSON.parse(
        JSON.stringify(this.lab.certificate_cost)
      )
    }
  }
}
</script>

<style lang="scss" scoped></style>
