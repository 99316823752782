<template>
  <div>
    <div class="q-gutter-md" style="margin-top: 20px">
      <div>{{ title }}</div>
      <OptCalculatorCard
        :title="card.title"
        :action="card.action"
        :id="card.id"
        :next="card.next"
        :range="card.range"
        :eye="eye"
        :color="color"
        @card-open="toggleOverlay"
        v-for="(card, key) in eyeData"
        :key="key"
      />
    </div>
  </div>
</template>
<script>
import OptCalculatorCard from '../../components/Calculator/OptCalculatorCard'

export default {
  name: 'ProductPrescription',

  props: {
    eye: {
      type: String,
      default: 'Right'
    },

    title: {
      type: String,
      default: 'Olho Direito'
    },

    color: {
      type: String
    },

    eyeData: {
      type: Array,
      default: () => []
    }
  },

  components: {
    OptCalculatorCard
  },

  data: () => ({
    overlay: false
  }),

  methods: {
    toggleOverlay(value) {
      this.overlay = value
    }
  }
}
</script>

<style lang="sass" scoped>
.orverlay
  position: absolute
  background: #00000094
  width: 100%
  height: 100%
  z-index: 1
  top: 0
  left: -16px
</style>
