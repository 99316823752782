<template>
  <div class="row mb-10">
    <div class="col-md-11">
      <b-form-select
        v-model="selected"
        class="mb-3"
        :options="options"
        :disabled="loader"
        @change="setManufacurer"
      ></b-form-select>
    </div>
    <div class="col-md-1">
      <b-button variant="danger" @click="open = !open">novo</b-button>
      <Manufacturerform
        :isOpen="open"
        @input="setManufacurer"
        @cancel="open = false"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Manufacturerform from '../Manufacturer/Manufacturerform'

export default {
  name: 'ManufacturerList',

  components: {
    Manufacturerform
  },

  props: ['manufacturer'],

  data: () => ({
    open: false,
    selected: null,
    items: []
  }),

  computed: {
    ...mapGetters('menus', {
      manufactures: 'getManufacturers',
      loader: 'getLoader'
    }),

    options() {
      return this.manufactures.flat().map((item) => ({
        value: item.value,
        text: item.label
      }))
    }
  },

  methods: {
    ...mapActions('menus', ['fetchManufacturer']),

    setManufacurer(id) {
      this.selected = id
      this.open = false
      this.$emit('input', id)
    }
  },

  mounted() {
    this.fetchManufacturer()
    if (this.manufacturer) {
      this.selected = this.manufacturer
    }
  }
}
</script>

<style lang="scss" scoped>
select {
  border: 1px solid #ccc !important;
}
</style>
