<template>
  <div>
    <q-card
      flat
      bordered
      v-for="(technology, key) in technologies.flat()"
      :key="key"
      @click.prevent.native="setValue(technology)"
    >
      <q-card-section class="self-center q-mt-md">
        <div class="text-h5">{{ technology.label.toUpperCase() }}</div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: 'ProductTechnologyManual',

  computed: {
    ...mapGetters('menus', {
      technologies: 'getTechnologies',
      loader: 'getLoader'
    })
  },

  methods: {
    ...mapActions('menus', [
      'addPanel',
      'setColors',
      'fetchProducts',
      'fetchIndex'
    ]),

    ...mapActions('orders', ['setTechnology', 'setColor']),

    async setValue(val) {
      await this.setTechnology(val.value)

      if (val.colors.length > 0) {
        // let loader = this.$loading.show()
        // this.$http
        //   .query('lists/colors', {
        //     params: { all: 1 }
        //   })
        //   .then(async ({ data }) => {
        //     const colors = data.map((color) => {
        //       return {
        //         label: color.name,
        //         value: color.id
        //       }
        //     })
        //     this.$store.commit('menus/SET_COLOR', _.chunk(colors, 2))
        //   })
        //   .finally(() => loader.hide())
      } else {
        this.$emit('input', true)
        let loader = this.$loading.show()
        this.$http
          .query('lists/indexes', {
            params: { all: 1 }
          })
          .then(async ({ data }) => {
            this.$store.commit('menus/SET_INDEX', _.chunk(data, 2))
          })
          .finally(() => loader.hide())
      }

      this.addPanel({
        ...val,
        action: 'removeTechnology'
      })
    }
  }
}
</script>
