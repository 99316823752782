<template>
  <div>
    <q-card
      flat
      bordered
      v-for="(treatment, key) in treatments.flat()"
      :key="key"
      @click.prevent.native="setValue(treatment)"
    >
      <q-card-section class="self-center q-mt-md">
        <div class="text-h5" v-html="treatment.label.toUpperCase()"></div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ProductTreatment',

  computed: {
    ...mapGetters('menus', {
      treatments: 'getTreatments'
    })
  },

  methods: {
    ...mapActions('menus', ['addPanel']),
    ...mapActions('orders', ['setTreatment']),

    setValue(val) {
      this.setTreatment(val.value)
      this.addPanel({
        ...val,
        action: 'removeTreatment'
      })
    }
  }
}
</script>
