<template>
  <div>My component</div>
</template>

<script>
export default {
  name: 'OrderAdminForm',

  data() {
    return {
      order: null
    }
  }
}
</script>
