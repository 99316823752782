<template>
  <router-view></router-view>
</template>

<style lang="scss">
@import '~animate.css';
@import '~@fortawesome/fontawesome-free/css/all.css';
@import 'assets/plugins/flaticon2/flaticon.css';
@import 'assets/plugins/keenthemes-icons/font/ki.css';
@import 'assets/sass/style.vue';
</style>

<script>
import { OVERRIDE_LAYOUT_CONFIG } from '@/core/services/store/config.module'
import ApiService from './core/services/api.service'

export default {
  name: 'MetronicVue',

  async beforeMount() {
    const data = await JSON.parse(localStorage.getItem('data'))
    this.$store.dispatch('setAuthInfo', data)
  },

  methods: {
    getUserStatus() {
      ApiService.get('/auth/me').then(({ data }) => {
        if (data.status === 0) {
          alert('Conta desativada, por falta de pagamento.')
          localStorage.clear()
          window.location.reload()
        }
      })
    }
  },

  mounted() {
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG)
    this.getUserStatus()
    setInterval(this.getUserStatus, 1000 * 60 * 60)
  }
}
</script>
