import ApiService from '../../api.service'

export function fetchOrders({ commit }, payload) {
  commit('SET_LOADER', true)
  let endpoint = '/orders'

  if ((payload && payload.month !== undefined) || payload.year !== undefined) {
    endpoint = `/orders?month=${payload.month}&year=${payload.year}`
  }

  if (payload.search) {
    endpoint = `/orders/?search=${encodeURI(payload.search)}`
  }

  return new Promise((resolve) => {
    ApiService.query(endpoint)
      .then((res) => {
        commit('SET_ORDERS', res.data)
        commit('SET_TOTAL', res.data.total)
        resolve(res.data)
      })
      .catch(({ response }) => {
        commit('SET_ORDERS', [])
        commit('SET_TOTAL', 0)
        commit('SET_ERRORS', response)
      })
      .finally(() => commit('SET_LOADER', false))
  })
}

export function resetState({ commit }) {
  commit('RESET_STATE')
}
export function setOrder({ commit }, payload) {
  commit('SET_ORDER', payload)
}

export function setPrism({ commit }, payload) {
  commit('SET_PRISM', payload)
}

export function setHoop({ commit }, payload) {
  commit('UPDATE_HOOP', payload)
}

export function setSendTo({ commit }, payload) {
  commit('UPDATE_SEND_TO', payload)
}

export function setCustomer({ commit }, payload) {
  commit('SET_CUSTOMER_DATA', payload)
}

export function setType({ commit }, payload) {
  commit('UPDATE_TYPE', payload)
}

export function setLens({ commit }, payload) {
  commit('UPDATE_LENS', payload)
}

export function setFrame({ commit }, payload) {
  commit('UPDATE_FRAME', payload)
}

export function setIndex({ commit }, payload) {
  commit('UPDATE_INDEX', payload)
}

export function setDoctor({ commit }, payload) {
  commit('UPDATE_DOCTOR', payload)
}

export function setTechnology({ commit }, payload) {
  commit('UPDATE_TECHNOLOGY', payload)
}

export function setColor({ commit }, payload) {
  commit('UPDATE_COLOR', payload)
}

export function setManufacturer({ commit }, payload) {
  commit('UPDATE_MANUFACTURER', payload)
}

export function setTreatment({ commit }, payload) {
  commit('UPDATE_TREATMENT', payload)
}

export function setProduct({ commit }, payload) {
  commit('UPDATE_PRODUCT', payload)
}

export function setVbox({ commit }, payload) {
  commit('UPDATE_VBOX', payload)
}
export function setHbox({ commit }, payload) {
  commit('UPDATE_HBOX', payload)
}
export function setDistanceBetweenLenses({ commit }, payload) {
  commit('UPDATE_DISTANCEBETWEENLENSES', payload)
}

export function setLargestDiameter({ commit }, payload) {
  commit('UPDATE_LARGESTDIAMETER', payload)
}

export function setAssemblyDiameter({ commit }, payload) {
  commit('UPDATE_ASSEMPLY_DIAMETER', payload)
}

export function setDominantEye({ commit }, payload) {
  commit('UPDATE_DOMINANT_EYE', payload)
}

export function setVmap({ commit }, payload) {
  commit('UPDATE_VMAP', payload)
}

export function setRemark({ commit }, payload) {
  commit('UPDATE_REMARK', payload)
}

export function setAdditionRightEye({ commit }, payload) {
  commit('UPDATE_ADDITION_RIGHT_EYE', payload)
}

export function setAxisRightEye({ commit }, payload) {
  commit('UPDATE_AXIS_RIGHT_EYE', payload)
}

export function setCylindricalRightEye({ commit }, payload) {
  commit('UPDATE_CYLINDRICAL_RIGHT_EYE', payload)
}

export function seFittingtHeightRightEye({ commit }, payload) {
  commit('UPDATE_FITTING_HEIGHT_RIGHT_EYE', payload)
}

export function setHorizontalPrismAxisRightEye({ commit }, payload) {
  commit('UPDATE_HORIZONTAL_PRISM_AXIS_RIGHT_EYE', payload)
}

export function setHorizontalPrismAxisValueRightEye({ commit }, payload) {
  commit('UPDATE_HORIZONTAL_PRISM_AXIS_VALUE_RIGHT_EYE', payload)
}

export function setVerticalPrismAxisRightEye({ commit }, payload) {
  commit('UPDATE_VERTICAL_PRISM_AXIS_RIGHT_EYE', payload)
}

export function setVerticalPrismAxisValueRightEye({ commit }, payload) {
  commit('UPDATE_VERTICAL_PRISM_AXIS_VALUE_RIGHT_EYE', payload)
}

export function setInterPupillaryDistanceRightEye({ commit }, payload) {
  commit('UPDATE_INTER_PUPILAR_DISTANCE_RIGHT_EYE', payload)
}

export function setSphericalRightEye({ commit }, payload) {
  commit('UPDATE_SPHERICAL_RIGHT_EYE', payload)
}

export function setCurvatureRightEye({ commit }, payload) {
  commit('UPDATE_CURVATURE_RIGHT_EYE', payload)
}
export function setPantographicRightEye({ commit }, payload) {
  commit('UPDATE_PANTOGRAPHIC_RIGHT_EYE', payload)
}
export function setEyecodeRightEye({ commit }, payload) {
  commit('UPDATE_EYECODE_RIGHT_EYE', payload)
}
export function setCvpRightEy({ commit }, payload) {
  commit('UPDATE_CVP_RIGHT_EYE', payload)
}
export function esphericalNearRight({ commit }, payload) {
  commit('UPDATE_SPHERICAL_NEAR_RIGHT_EYE', payload)
}

export function setAdditionLeftEye({ commit }, payload) {
  commit('UPDATE_ADDITION_LEFT_EYE', payload)
}

export function setAxisLeftEye({ commit }, payload) {
  commit('UPDATE_AXIS_LEFT_EYE', payload)
}

export function setCylindricalLeftEye({ commit }, payload) {
  commit('UPDATE_CYLINDRICAL_LEFT_EYE', payload)
}

export function seFittingtHeightLeftEye({ commit }, payload) {
  commit('UPDATE_FITTING_HEIGHT_LEFT_EYE', payload)
}

export function setHorizontalPrismAxisLeftEye({ commit }, payload) {
  commit('UPDATE_HORIZONTAL_PRISM_AXIS_LEFT_EYE', payload)
}

export function setHorizontalPrismAxisValueLeftEye({ commit }, payload) {
  commit('UPDATE_HORIZONTAL_PRISM_AXIS_VALUE_LEFT_EYE', payload)
}

export function setVerticalPrismAxisLeftEye({ commit }, payload) {
  commit('UPDATE_VERTICAL_PRISM_AXIS_LEFT_EYE', payload)
}

export function setVerticalPrismAxisValueLeftEye({ commit }, payload) {
  commit('UPDATE_VERTICAL_PRISM_AXIS_VALUE_LEFT_EYE', payload)
}

export function setInterPupillaryDistanceLeftEye({ commit }, payload) {
  commit('UPDATE_INTER_PUPILAR_DISTANCE_LEFT_EYE', payload)
}

export function setSphericalLeftEye({ commit }, payload) {
  commit('UPDATE_SPHERICAL_LEFT_EYE', payload)
}

export function setCurvatureLeftEye({ commit }, payload) {
  commit('UPDATE_CURVATURE_LEFT_EYE', payload)
}
export function setPantographicLeftEye({ commit }, payload) {
  commit('UPDATE_PANTOGRAPHIC_LEFT_EYE', payload)
}
export function setEyecodeLeftEye({ commit }, payload) {
  commit('UPDATE_EYECODE_LEFT_EYE', payload)
}
export function setCvpLeftEy({ commit }, payload) {
  commit('UPDATE_CVP_LEFT_EYE', payload)
}
export function setEsphericalNearLeft({ commit }, payload) {
  commit('UPDATE_SPHERICAL_NEAR_LEFT_EYE', payload)
}
