<template>
  <div class="form-group">
    <div v-for="(user, k) in users" :key="k" class="usersList">
      <div class="userInfo">
        {{ user.name }} <br />
        <small>{{ user.email }}</small
        ><br />
        <small>{{ user.phone }}</small>
      </div>
      <div>
        <button
          type="button"
          class="btn btn-light-primary font-weight-bolder"
          @click="removeUser(k)"
        >
          X
        </button>
      </div>
    </div>
    <div class="form-group" style="margin-top: 20px">
      <input
        class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
        type="text"
        placeholder="Nome Completo"
        v-model="user.name"
        name="name"
        ref="userName"
        autocomplete="off"
      />
    </div>
    <div class="form-group" style="margin-top: 20px">
      <input
        class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
        type="text"
        placeholder="Email"
        v-model="user.email"
        name="email"
        ref="userEmail"
        autocomplete="off"
      />
    </div>
    <div class="form-group">
      <input
        class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
        type="text"
        placeholder="Celular"
        v-model="user.phone"
        name="name"
        autocomplete="off"
        v-mask="['(##) #####-####']"
      />
    </div>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'

export default {
  name: 'SalesmenInvite',

  directives: { mask },

  data: () => ({
    users: [],
    user: {
      name: null,
      phone: null,
      email: null
    }
  }),

  watch: {
    user: {
      handler(nv) {
        if (nv.name && nv.phone && nv.email) {
          if (nv.name.length > 1 && nv.phone.length === 15) {
            this.addUser()
          }
        }
      },
      deep: true
    }
  },

  methods: {
    addUser() {
      if (!this.user.name && !this.user.phone && !this.user.email) {
        return
      }
      const user = JSON.parse(JSON.stringify(this.user))
      this.users.push(user)
      this.$emit('input', this.user)
      this.user = {
        name: null,
        phone: null,
        email: null
      }
      this.$nextTick(() => this.$refs.userName.focus())
    },

    removeUser(k) {
      this.users.splice(k, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.usersList {
  display: flex;
  border: 1px solid #ecf3f9;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  -webkit-box-shadow: 1px 1px 1px 1px #0000000a;
  box-shadow: 1px 1px 1px 1px #0000000a;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.userInfo {
  padding-left: 10px;
  font-size: 1.2em;
}
</style>
