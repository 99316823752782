<template>
  <div>
    <br />
    <q-card class="q-pa-lg q-ma-md">
      <form
        @submit.prevent.stop="onSubmit"
        @reset.prevent.stop="onReset"
        class="q-gutter-md"
      >
        <q-input
          ref="name"
          color="indigo-14"
          v-model="name"
          label="Nome *"
          lazy-rules
          :rules="rules.name"
        />

        <q-input
          ref="email"
          color="indigo-14"
          v-model="email"
          label="Email *"
          lazy-rules
          :rules="rules.email"
        />

        <q-input
          ref="password"
          color="indigo-14"
          v-model="password"
          label="Senha *"
          lazy-rules
          :rules="rules.password"
        />

        <q-input
          ref="password_confirmation"
          color="indigo-14"
          v-model="password_confirmation"
          label="Confirmar Senha *"
          lazy-rules
          :rules="rules.password_confirmation"
        />

        <div>
          <q-btn
            label="Salvar"
            type="submit"
            color="indigo-14"
            flat
            unelevated
          />
          <q-btn
            label="Cancelar"
            color="grey-13"
            flat
            class="q-ml-sm"
            @click="$router.push('/vendedores')"
            unelevated
          />
        </div>
      </form>
    </q-card>
  </div>
</template>

<script>
export default {
  name: 'Salesmanform',

  data() {
    return {
      title: 'Cadastrar Vendedor',
      id: null,
      name: null,
      email: null,
      password: null,
      password_confirmation: null,
      rules: {
        name: [
          (val) =>
            (val && val.length > 0) || 'Por favor digite o nome do vendedor'
        ],
        email: [
          (val) =>
            (val && val.length > 0) || 'Por favor digite o email do vendedor'
        ],
        password: [
          (val) =>
            (val && val.length > 0) ||
            'Por favor crie uma senha para o vendedor'
        ],
        password_confirmation: [
          (val) =>
            (val && val.length > 0) || 'Por favor repita senha para o vendedor'
        ]
      }
    }
  },

  computed: {
    params() {
      return {
        name: this.name,
        email: this.email,
        password: this.password,
        password_confirmation: this.password_confirmation
      }
    }
  },

  methods: {
    onSubmit() {
      this.$refs.name.validate()
      this.$refs.email.validate()
      this.$refs.password.validate()
      this.$refs.password_confirmation.validate()

      if (
        this.$refs.name.hasError ||
        this.$refs.email.hasError ||
        this.$refs.password.hasError ||
        this.$refs.password_confirmation.hasError
      ) {
        return true
      }
      this.save()
    },

    save() {
      this.$loading.show()

      const config = {
        method: this.id ? 'PUT' : 'POST',
        url: this.id ? `/salesman/${this.id}` : '/salesman',
        data: this.params
      }

      this.$axios(config)
        .then((res) => {
          this.$q.notify({
            progress: true,
            timeout: 2000,
            position: 'top',
            message: res.data.msg,
            color: 'secondary',
            multiLine: true,
            avatar: '/favicon.png'
          })
          this.$router.push('/vendedores')
        })
        .finally(() => this.$loading.hide())
    },

    getSalesMan() {
      this.$loading.show()
      this.$axios
        .get(`/salesman/${this.id}`)
        .then((res) => {
          const data = res.data
          this.title = data.name
          this.name = data.name
          this.email = data.email
        })
        .finally(() => this.$loading.hide())
    },

    onReset() {
      this.name = null
      this.email = null
      this.password = null
      this.password_confirmation = null
      this.$refs.name.resetValidation()
      this.$refs.email.resetValidation()
      this.$refs.password.resetValidation()
      this.$refs.password_confirmation.resetValidation()
    }
  },

  beforeMount() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id
      this.getSalesMan()
    }
  }
}
</script>
