import JwtService from '@/core/services/jwt.service'
import axios from 'axios'
import Paths from './paths.service'

import VueSimpleAlert from 'vue-simple-alert'
/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  loader: false,

  http: null,

  init() {
    const options = {
      baseURL: Paths.API,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    }

    const http = axios.create(options)

    http.interceptors.request.use(
      async (config) => {
        if (!config.url.includes('auth')) {
          config.headers.Authorization = `Bearer ${JwtService.getToken()}`
        }
        if (config.url.includes('auth/me')) {
          config.headers.Authorization = `Bearer ${JwtService.getToken()}`
        }
        return config
      },
      (error) => Promise.reject(error)
    )

    http.interceptors.response.use(
      async (response) => {
        return response
      },
      (error) => {
        const status = error.response.status
        if (
          status === 400 ||
          status === 402 ||
          status === 403 ||
          status === 422
        ) {
          const msg = Object.values(error.response.data.errors)
            .flat()
            .join('\n')

          VueSimpleAlert.alert(msg)
        }

        if (status >= 500) {
          // localStorage.clear()
          // window.location.reload(true)
        }

        return Promise.reject(error)
      }
    )

    return http
  },

  query(resource, params) {
    const http = this.init()
    return http.get(resource, params).catch((error) => {
      throw new Error(`[KT] ApiService ${error}`)
    })
  },

  get(resource, slug = '') {
    const http = this.init()
    return http.get(`${resource}/${slug}`).catch((error) => {
      throw new Error(`[KT] ApiService ${error}`)
    })
  },

  post(resource, params) {
    const http = this.init()
    return http.post(`${resource}`, params)
  },

  update(resource, slug, params) {
    const http = this.init()
    return http.put(`${resource}/${slug}`, params)
  },

  put(resource, params) {
    const http = this.init()
    return http.put(`${resource}`, params)
  },

  delete(resource) {
    const http = this.init()
    return http.delete(resource).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`)
    })
  }
}

export default ApiService
