<template>
  <div class="calculator" v-if="isOpen">
    <div>
      <div v-for="(rows, k) in keyboard" :key="k">
        <div :class="{ row: true, first: k === 0 }">
          <div
            class="col button"
            v-for="(col, j) in rows"
            :key="j"
            :style="`background:${col.color}`"
            @click="setValue(col)"
            :id="col.value === 'back' ? 'backbutton' : ''"
          >
            <div name="backspace" v-if="col.value === 'back'">
              <img src="/backspace.png" />
            </div>
            <div v-else v-html="col.label ? col.label : col.value"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import keyboards from '../../core/services/dummy_data/keyboards'

export default {
  name: 'OptCalculator',

  props: {
    type: {
      type: String,
      default: 'normal'
    },

    customKeyboard: {
      type: Array,
      default: () => []
    },

    clear: {
      type: Number,
      defalt: 0
    },

    field: {
      type: String,
      defalt: ''
    },

    isOpen: {
      type: Boolean
    },

    reset: {
      type: String,
      defalt: 'n'
    }
  },

  data: () => ({
    totalRemoved: 0,
    value: '',
    keyboard: [],
    positive: true
  }),

  watch: {
    field(val, oval) {
      if (val !== oval) {
        this.value = ''
      }
    },

    isOpen(val) {
      if (!val) {
        this.value = ''
      }
    }
  },

  methods: {
    setValue(obj) {
      if (obj.value === 'back') {
        if (this.value.length > 0) {
          this.value = this.value.slice(0, -1)
        } else {
          this.value = ''
        }

        this.$emit('input-change', {
          field: this.field,
          value: this.value,
          close: false
        })

        return true
      }

      this.value = `${this.value}${obj.value}`

      if (obj.value === '+/-') {
        this.positive = !this.positive
        if (!this.positive) {
          this.value = `-${this.value}`.replace('+/-', '')
        } else {
          this.value = `${this.value}`.replace('+/-', '').replace('-', '')
        }

        this.$emit('input-change', {
          field: this.field,
          value: this.value,
          close: false
        })
        return true
      }
      if (obj.value === '.') {
        this.value = `${this.value}`

        this.$emit('input-change', {
          field: this.field,
          value: this.value,
          close: false
        })
        return true
      }

      if (obj.value === '.50' || obj.value === '.75') {
        this.value = `${this.value}`
        this.$emit('input-change', {
          field: this.field,
          value: this.value,
          close: false
        })
        return true
      }

      if (obj.value === 'OK') {
        this.value = this.value.replace('OK', '')
        this.$emit('ok', {
          field: this.field,
          value: this.value,
          close: true
        })
        this.value = ''
        return true
      }

      this.$emit('input-change', {
        field: this.field,
        value: this.value,
        close: false
      })
    },

    closeCalculator() {
      this.$emit('close', {
        field: this.field,
        value: this.total,
        close: false
      })
    }
  },

  created() {
    this.keyboard = keyboards.minimal

    if (this.customKeyboard.length > 0) {
      this.keyboard = this.customKeyboard
    }
  }
}
</script>

<style lang="sass" scoped>
.calculator
  position: fixed
  bottom: 0
  background: #e8e8e8
  width: 100%
  z-index: 1000
  left:0
  right: 0
  // margin-left: -4px
  box-shadow: -2px -4px 14px 0px #00000045
.button
  text-align: center
  border: 1px solid #dcdcdc
  padding: 15px 10px
  font-size: 1.2em
  // font-family: 'poppinsregular'!important
  display: flex
  align-items: center
  justify-content: space-around
  text-transform: uppercase
.button:hover
  background: #304ffe!important
  color: #fff!important
</style>
