import ApiService from '../api.service'
import { getToken, saveToken, destroyToken } from '../jwt.service'

export const VERIFY_AUTH = 'verifyAuth'
export const LOGIN = 'login'
export const LOGOUT = 'logout'
export const REGISTER = 'register'
export const UPDATE_PASSWORD = 'updateUser'
export const SET_USER_INFO = 'setUserInfo'
export const SET_STORE_INFO = 'setStoreInfo'
export const UPDATE_STORE = 'updateStore'
export const UPDATE_ME = 'updateMe'
export const GET_INVITE = 'fetchInvite'
export const ACTIVATE = 'ACTIVATE'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const FETCH_USER_INFO = 'FETCH_USER_INFO'

// mutation types
export const PURGE_AUTH = 'logOut'
export const SET_AUTH_INFO = 'setAuthInfo'
export const SET_AUTH = 'setAuth'
export const SET_LAB = 'setLab'
export const SET_USER = 'setUser'
export const SET_STORE = 'setStore'
export const SET_PASSWORD = 'setPassword'
export const SET_ERROR = 'setError'
export const SET_STORES = 'setStores'
export const SET_CREDITS = 'setCredits'

const getters = {
  currentUser(state) {
    return state.user
  },
  isAuthenticated(state) {
    return state.isAuthenticated
  },
  store(state) {
    return state.store
  },
  getErrors(state) {
    return state.errors
  }
}

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post('auth/login', credentials)
        .then(({ data }) => {
          localStorage.setItem('data', JSON.stringify(data))
          this._vm.$setItem('user', data.user)
          this._vm.$setItem('store', data.store)
          context.commit(SET_AUTH, data)
          context.commit(SET_LAB, data.has_lab)
          if (data.status === 0) {
            alert('Conta desativada, por falta de pagamento.')
            localStorage.clear()
            window.location.reload()
          }
          resolve(data)
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors)
        })
    })
  },

  [ACTIVATE](context, token) {
    return new Promise((resolve) => {
      localStorage.clear()
      ApiService.post('auth/activate', token)
        .then(({ data }) => {
          localStorage.setItem('data', JSON.stringify(data))
          this._vm.$setItem('user', data.user)
          this._vm.$setItem('store', data.store)
          context.commit(SET_AUTH, data)
          context.commit(SET_LAB, data.has_lab)
          const dados = JSON.parse(localStorage.getItem('data'))
          dados.store = data.store
          dados.store.cofirmed = 1
          localStorage.setItem('data', JSON.stringify(dados))
          state.store.cofirmed = 1
          this._vm.$alert('Sua conta foi ativada com sucesso!')
          resolve(data)
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors)
        })
    })
  },

  [FORGOT_PASSWORD](context, payload) {
    return new Promise((resolve) => {
      ApiService.post('auth/forgot', payload)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors)
        })
    })
  },

  [FETCH_USER_INFO](context, payload) {
    return new Promise((resolve) => {
      ApiService.post('auth/fetch', payload)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors)
        })
    })
  },

  [RESET_PASSWORD](context, payload) {
    return new Promise((resolve) => {
      ApiService.post('auth/reset', payload)
        .then(({ data }) => {
          localStorage.setItem('data', JSON.stringify(data))
          this._vm.$setItem('user', data.user)
          this._vm.$setItem('store', data.store)
          context.commit(SET_AUTH, data)
          context.commit(SET_LAB, data.has_lab)
          resolve(data)
          resolve(data)
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors)
        })
    })
  },

  [GET_INVITE](context, token) {
    return new Promise((resolve) => {
      ApiService.query('invite', { params: { token: token } })
        .then(({ data }) => {
          resolve(data)
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors)
        })
    })
  },

  [SET_USER_INFO](context, payload) {
    context.commit(SET_USER, payload)
  },

  [SET_AUTH_INFO](context, payload) {
    if (payload) {
      this._vm.$setItem('user', payload.user)
      this._vm.$setItem('store', payload.store)
      context.commit(SET_AUTH, payload)
      context.commit(SET_LAB, payload.has_lab)
    }
  },

  [SET_STORE_INFO](context, payload) {
    context.commit(SET_STORE, payload)
  },

  [LOGOUT](context) {
    this._vm.$clearStorage()
    localStorage.clear()
    context.commit(PURGE_AUTH)
  },

  [REGISTER](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post('auth/signup', credentials)
        .then(({ data }) => {
          localStorage.setItem('data', JSON.stringify(data))
          this._vm.$setItem('user', data.user)
          this._vm.$setItem('store', data.store)
          context.commit(SET_AUTH, data)
          context.commit(SET_LAB, data.has_lab)
          resolve(data)
        })
        .catch((error) => {
          context.commit(SET_ERROR, error.response.data.errors)
        })
    })
  },

  [VERIFY_AUTH](context) {
    if (getToken()) {
      // ApiService.get("auth/verify")
      //   .then(({ data }) => {
      //     context.commit(SET_AUTH, data)
      //   })
      //   .catch(({ response }) => {
      //     context.commit(SET_ERROR, response.data.errors)
      //   })
    } else {
      context.commit(PURGE_AUTH)
    }
  },

  [UPDATE_PASSWORD](context, payload) {
    const password = payload

    return ApiService.put('password', password).then(({ data }) => {
      context.commit(SET_PASSWORD, data)
      return data
    })
  },

  [UPDATE_STORE](context, payload) {
    return ApiService.update('stores', payload.id, payload.data).then(
      ({ data }) => {
        localStorage.setItem('data', JSON.stringify(data))
        this._vm.$setItem('user', data.user)
        this._vm.$setItem('store', data.store)
        context.commit(SET_AUTH, data)
        context.commit(SET_LAB, data.has_lab)
        return data
      }
    )
  },

  [UPDATE_ME](context, payload) {
    return ApiService.put('me', payload).then(({ data }) => {
      localStorage.clear()
      localStorage.setItem('data', JSON.stringify(data))
      this._vm.$setItem('user', data.user)
      this._vm.$setItem('store', data.store)
      context.commit(SET_AUTH, data)
      context.commit(SET_LAB, data.has_lab)
      this._vm.$alert('perfil atualizado com successo!')
      return data
    })
  }
}

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error
  },

  async [SET_CREDITS](state) {
    const count = state.store.credits - 1
    state.store.credits = count
    const data = await JSON.parse(localStorage.getItem('data'))
    data.store.credits = count
    await localStorage.setItem('data', JSON.stringify(data))
  },

  [SET_AUTH](state, data) {
    state.isAuthenticated = true
    state.user = data.user
    state.roles = data.roles
    state.store = data.store
    state.stores = data.stores
    state.errors = {}
    saveToken(data.access_token)
  },

  [SET_PASSWORD](state, password) {
    state.user.password = password
  },

  [SET_USER](state, payload) {
    state.user = payload
  },

  [SET_LAB](state, payload) {
    state.user.has_lab = payload
  },

  [SET_STORE](state, payload) {
    state.store = payload
  },

  [PURGE_AUTH](state) {
    state.isAuthenticated = false
    state.user = {}
    state.errors = {}
    destroyToken()
  }
}

const state = {
  errors: null,
  roles: null,
  store: null,
  stores: null,
  user: {},
  isAuthenticated: !!getToken()
}

export default {
  state,
  actions,
  mutations,
  getters
}
