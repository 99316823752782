<template>
  <div class="col button" @click="compute" :style="`background:${color}`">
    <slot>
      {{ value }}
    </slot>
  </div>
</template>

<script>
export default {
  name: 'OptCalculatorButton',

  props: {
    value: {
      type: [String, Number],
      required: true,
      default: ''
    },
    color: {
      type: String,
      default: '#ffffff'
    }
  },

  data: () => ({
    total: null
  })
}
</script>

<style lang="sass" scoped>
.button
  text-align: center
  border: 1px solid #dcdcdc
  padding: 10px 10px
  font-size: 1.2em
  display: flex
  align-items: center
  justify-content: space-around
.button:hover
  background: #e6ffe0!important
</style>
