import { render, staticRenderFns } from "./FrameHoops.vue?vue&type=template&id=f17a6590&scoped=true&"
import script from "./FrameHoops.vue?vue&type=script&lang=js&"
export * from "./FrameHoops.vue?vue&type=script&lang=js&"
import style0 from "./FrameHoops.vue?vue&type=style&index=0&id=f17a6590&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f17a6590",
  null
  
)

export default component.exports