<template>
  <b-modal
    id="manufacturer-modal"
    title="Cadastrar Fabricante"
    @ok="save"
    @cancel="cancel"
    @close="cancel"
  >
    <div v-if="loading" class="text-center">
      <b-spinner
        style="width: 4rem; height: 4rem"
        label="Large Spinner"
      ></b-spinner>
    </div>
    <p v-else class="my-4">
      <b-input placeholder="Nome" color="indigo-14" v-model="name" />
    </p>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Manufacturerform',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    isOpen(val) {
      if (val === true) {
        this.$bvModal.show('manufacturer-modal')
      }
      if (val === false) {
        this.$bvModal.hide('manufacturer-modal')
      }
    }
  },

  data: () => ({
    name: null,
    loading: false
  }),

  methods: {
    ...mapActions('menus', ['pushManufacturerList']),

    cancel() {
      this.$emit('cancel', true)
    },

    save(e) {
      e.preventDefault()
      this.loading = false
      this.$http
        .post('/manufacturers', { name: this.name })
        .then(({ data }) => {
          this.pushManufacturerList({
            value: data.id,
            label: data.name
          })
          this.$emit('input', data.id)
          this.$bvModal.hide('manufacturer-modal')
        })
        .finally(() => (this.loading = true))
    }
  }
}
</script>
