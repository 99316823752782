<template>
  <div>
    <div class="orverlay" v-if="openCalculator"></div>
    <OptCalculator
      v-if="openCalculator"
      :customKeyboard="hoops"
      @input-change="setValue"
    />
    <q-card
      flat
      bordered
      @click="openCalculator = !openCalculator"
      :style="openCalculator ? 'z-index:4' : ''"
    >
      <q-card-section class="self-center q-mt-md">
        <q-spinner color="primary" size="3em" v-if="loading" />
        <div class="text-h5">{{ title }}</div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import OptCalculator from '../Calculator/OptCalculator'

export default {
  name: 'FrameHoops',

  components: { OptCalculator },

  data: () => ({
    title: 'TIPO DE ARMAÇÃO',
    id: 'hoop_id',
    range: [],
    openCalculator: false
  }),

  watch: {
    openCalculator(val) {
      this.$emit('toggle-overlay', val)
      this.$emit('go-to', 'product_id')
    }
  },

  computed: {
    hoops() {
      return this.$store.getters['menus/getHoops']
    },

    loading() {
      return !!this.$store.getters['menus/getLoader']
    }
  },

  methods: {
    setValue(val) {
      const type = this.hoops.flat().filter((e) => e.value === val)[0]
      this.title = type.label
      this.openCalculator = false
      this.$store.dispatch('orders/setHoop', val)
    }
  }
}
</script>

<style lang="sass" scoped>
.orverlay
  position: absolute
  background: #00000094
  width: 100%
  height: 100%
  z-index: 3!important
  top: 0
  right: 0
</style>
