import { render, staticRenderFns } from "./OptCalculatorButton.vue?vue&type=template&id=72a288a0&scoped=true&"
import script from "./OptCalculatorButton.vue?vue&type=script&lang=js&"
export * from "./OptCalculatorButton.vue?vue&type=script&lang=js&"
import style0 from "./OptCalculatorButton.vue?vue&type=style&index=0&id=72a288a0&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72a288a0",
  null
  
)

export default component.exports