<template>
  <q-btn
    unelevated
    :loading="loading"
    color="green"
    size="sm"
    icon-right="refresh"
    :label="name"
    @click.prevent.stop="refresh"
  />
</template>

<script>
export default {
  name: 'OrderRefresh',

  props: {
    name: {
      type: String,
      default: 'Pendente'
    },
    order: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    loading: false
  }),

  methods: {
    refresh() {
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 3000)
    }
  }
}
</script>
