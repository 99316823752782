<template>
  <div>
    <q-select
      filled
      v-model="model"
      label="Fonecedores"
      :loading="loading"
      :options="list"
      @virtual-scroll="onScroll"
    >
      <template v-slot:no-option>
        <q-item>
          <q-item-section class="text-grey"> No results </q-item-section>
        </q-item>
      </template>
    </q-select>
  </div>
</template>

<script>
const options = []
for (let i = 0; i <= 100000; i++) {
  options.push('Opt ' + i)
}

// const pageSize = 51
// const lastPage = Math.ceil(options.length / pageSize)

export default {
  name: 'ManufacturerSelectList',

  data() {
    return {
      model: null,
      loading: false,
      nextPage: 1,
      items: [],
      list: [],
      pagination: {
        count: 50,
        per_page: 50,
        current_page: 0,
        total: 0,
        total_pages: 0
      }
    }
  },

  watch: {
    model(newVal) {
      this.$emit('select', newVal)
    }
  },

  computed: {
    url() {
      if (this.nextPage === 1) {
        return '/manufacturers'
      }

      return `/manufacturers/?page=${this.nextPage}`
    }
  },

  methods: {
    onScroll() {
      this.nextPage = this.pagination.current_page + 1
      if (this.nextPage <= this.pagination.total_pages) {
        this.getList()
      }
    },

    createList() {
      return this.items.data.forEach((item) =>
        this.list.push({
          value: item.id,
          label: item.name
        })
      )
    },

    getList() {
      this.loading = true
      this.$axios
        .get(this.url)
        .then(async (res) => {
          this.items = await res.data
          this.pagination = this.items.pagination
          this.createList()
        })
        .finally(() => (this.loading = false))
    },

    abortFilterFn() {}
  },

  mounted() {
    this.getList()
  }
}
</script>
