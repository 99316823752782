let HOST = window.location.host
let API = 'https://api.opticonecta.app/api'

if (HOST.includes('localhost')) {
  API = 'http://api.opticonecta.local/api'
}

if (HOST.includes('teste')) {
  API = 'https://testeapi.opticonecta.app/api'
}

const Urls = {
  API: API
}

export default Urls
