<template>
  <div class="optCard">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'qCard'
}
</script>

<style lang="scss" scoped>
.optCard {
  border-radius: 10px;
  background: #fff !important;
  border: none !important;
  vertical-align: top;
  position: relative;
  margin-top: 16px;
  padding: 16px;
}
</style>
