<template>
  <div style="margin-top: 160px">
    <div style="margin-top: 20px"><h2>Detalhes armação:</h2></div>
    <q-card>
      <q-card-section>
        <div class="row">
          <div class="col">
            <b-input
              placeholder="Horizontal"
              color="indigo-14"
              v-model="frame.hBox"
              @change="dispatchFrame"
            />
          </div>
          <div class="col">
            <b-input
              placeholder="Vertical"
              color="indigo-14"
              v-model="frame.vBox"
              @change="dispatchFrame"
            />
          </div>
        </div>

        <div class="row">
          <div class="col">
            <b-input
              class="mt-5"
              placeholder="Distância entre as lentes"
              color="indigo-14"
              v-model="frame.distanceBetweenLenses"
              @change="dispatchFrame"
            />
          </div>
        </div>

        <div class="row">
          <div class="col">
            <b-input
              class="mt-5"
              placeholder="Diagonal maior"
              color="indigo-14"
              :rules="[
                (val) => !!val || 'Diagonal maior é um campo obrigatório'
              ]"
              v-model="frame.largerDiagonal"
              @input="dispatchFrame"
            />
          </div>
        </div>

        <div class="row" v-if="hasMark === 'S'">
          <div class="col">
            <b-input
              class="mt-5"
              placeholder="Marcação na lente"
              color="indigo-14"
              @input="setRemark"
              mask="NNN"
            />
          </div>
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ProductFrameDetails',

  data: () => ({
    frame: {
      vBox: null,
      hBox: null,
      distanceBetweenLenses: null,
      largestDiameter: null,
      assemblyDiameter: null,
      largerDiagonal: null
    },
    remark: null
  }),

  computed: {
    ...mapGetters('orders', { order: 'getNewOrder', hasMark: 'GET_MARK' })
  },

  methods: {
    ...mapActions('orders', ['setRemark', 'setFrame']),

    dispatchFrame() {
      const val = JSON.parse(JSON.stringify(this.frame))
      this.setFrame(val)
    }
  }
}
</script>
