<template>
  <div>
    <q-card
      flat
      bordered
      v-for="(index, key) in indexes.flat()"
      :key="key"
      @click.prevent.native="setValue(index)"
    >
      <q-card-section class="self-center q-mt-md">
        <div class="text-h5">
          {{ index.label.toUpperCase() }}
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: 'ProductIndex',

  props: ['manual'],

  computed: {
    ...mapGetters('menus', {
      indexes: 'getIndexes'
    })
  },

  methods: {
    ...mapActions('menus', ['addPanel', 'fetchTreatment']),
    ...mapActions('orders', ['setIndex']),

    setValue(val) {
      this.setIndex(val.value)
      this.addPanel({
        ...val,
        action: 'removeIndex'
      })
      this.fetchTreatment()
    }
  },

  mounted() {
    let loader = this.$loading.show()
    this.$http
      .query('lists/indexes', {
        params: { all: 1 }
      })
      .then(async ({ data }) => {
        this.$store.commit('menus/SET_INDEX', _.chunk(data, 2))
      })
      .finally(() => loader.hide())
  }
}
</script>
