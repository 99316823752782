<template>
  <div>
    <div v-if="certificates.length > 0">
      <div v-for="(cert, k) in certificates" :key="k" flat>
        <b-card img-alt="Image" img-top class="mb-2">
          <div class="row">
            <div class="col">
              <h3 class="mt-5">
                <strong>{{ cert.data.customer.name }}</strong>
              </h3>
              <div>{{ cert.data.customer.document }}</div>
              <br />
              <div v-if="getCustomerPhone(cert.order)">
                <a
                  class="btn btn-success btn-sm"
                  :href="getCustomerPhone(cert.order)"
                  >CHAMAR NO WHATS</a
                >
              </div>
            </div>
            <div class="col text-right">
              <div>
                Emitido em:
                <strong>{{ cert.created_at | moment('DD-MM-YYYY') }}</strong>
              </div>
              <div>
                Válido até:
                <strong>{{ cert.expires_at | moment('DD-MM-YYYY') }}</strong>
              </div>
              <div>
                Emitido por:
                <strong>{{ cert.user.name }}</strong>
              </div>
            </div>
          </div>
          <div class="row mt-4 pt-5" style="border-top: 1px solid #ccc">
            <div class="col">
              <div v-for="(item, idx) in cert.data.info" :key="idx">
                <strong v-if="item.value">{{ item.label }}</strong>
                <br v-if="item.value" />
                <label v-if="item.value">{{ item.value }}</label>
              </div>
            </div>
          </div>
        </b-card>
      </div>
    </div>
    <div class="alert alert-warning" v-else>
      <strong>Desculpe!</strong> Não existe nenhum resultado para sua busca.
    </div>
  </div>
</template>

<script>
export default {
  name: 'CertificatesList',

  data: () => ({
    loading: false
  }),

  computed: {
    certificates() {
      return this.$store.state.certificates.certificates
    }
  },

  methods: {
    setCustomerName(customer) {
      this.name = customer.name
    },

    getCustomerName(order) {
      if (order.data) {
        if (order.data.orderMembers) {
          if (order.data.orderMembers.length > 0) {
            const customer = order.data.orderMembers.filter(
              (item) => item.orderMemberType.description === 'PACIENTE'
            )
            if (customer.length == 1) {
              return customer[0].name
            }
          }
        }
      }

      if (order.customer) {
        if (order.customer.name) {
          return order.customer.name
        }
      }

      return 'SEM NOME'
    },

    getCustomerDocument(order) {
      if (order.customer) {
        if (order.customer.document) {
          return order.customer.document
        }
      }
      return null
    },

    getCustomerEmail(order) {
      if (order.customer) {
        if (order.customer.email) {
          return order.customer.email
        }
      }
      return null
    },

    getCustomerPhone(cert) {
      if (cert?.order?.customer?.phone) {
        const number = cert.data.customer.phone
          .replace('(', '')
          .replace(')', '')
          .replace(' ', '')
          .replace('-', '')

        return `https://api.whatsapp.com/send?phone=55${number}`
      }
      return null
    }
  }
}
</script>

<style lang="css">
.disabled {
  opacity: 0.6;
}
.card-body {
  padding: 2rem;
}
</style>
