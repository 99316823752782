<template>
  <div>
    <q-card
      flat
      bordered
      v-for="(hoop, key) in hoops.flat()"
      :key="key"
      @click.prevent.native="setValue(hoop)"
    >
      <q-card-section class="self-center q-mt-md">
        <div class="text-h5">
          {{ hoop.label.toUpperCase() }} <br />
          {{ currency(hoop.price) }}
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ProductFrame',

  computed: {
    ...mapGetters('menus', {
      hoops: 'getHoops'
    })
  },

  methods: {
    ...mapActions('menus', ['addPanel']),
    ...mapActions('orders', ['setHoop']),

    currency(value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return `R$ ${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`
    },

    setValue(val) {
      val.label = `${val.label}<br/>${this.currency(val.price)}`
      this.setHoop(val.value)
      this.addPanel({
        ...val,
        action: 'removeHoop'
      })
    }
  }
}
</script>
