<template>
  <div>
    <q-card
      flat
      bordered
      v-for="(manufacturer, key) in manufactureres.flat()"
      :key="key"
      @click.prevent.native="setValue(manufacturer)"
    >
      <q-card-section class="self-center q-mt-md">
        <div class="text-h5" v-html="manufacturer.label.toUpperCase()"></div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import _ from 'lodash'

export default {
  name: 'ProductManufacturer',

  computed: {
    ...mapGetters('menus', {
      manufactureres: 'getManufacturers'
    }),

    ...mapGetters('orders', {
      order: 'getNewOrder'
    }),

    type() {
      return this.order.type_id
    },

    manufacturer() {
      return this.order.manufacturer_id
    }
  },

  methods: {
    ...mapActions('menus', ['addPanel', 'fetchProducts', 'setTechnology']),

    ...mapActions('orders', ['setManufacturer']),

    async setValue(val) {
      let loader = this.$loading.show()
      await this.setManufacturer(val.value)
      let params = {
        type_id: this.type,
        manufacturer_id: this.manufacturer
      }

      if (this.$route.query.manual == 1) {
        params = { all: 1 }
      }
      this.$http
        .query('/lists/technologies', {
          params: params
        })
        .then(({ data }) => {
          this.$store.commit('menus/SET_TECHNOLOGY', _.chunk(data, 2))
        })
        .finally(() => loader.hide())

      this.setManufacturer(val.value)
      this.addPanel({
        ...val,
        action: 'removeManufacturer'
      })
    }
  }
}
</script>
